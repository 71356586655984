import React, { Component } from "react";

import * as d3 from "d3";

import "./DataSetTable.css";

export default class DataSetTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: props.data,
      original_data: props.data,
      filters: new Array(this.props.data[0].search.length).fill(""),
      top: 25,
      filterLogic: "All"
    };
    this.displayOptions = ["None", "25", "50", "100", "250", "500", "All"];
    this.displayOptionsRows = [0, 25, 50, 100, 250, 500, "Infinity"];

    this.filterKeys = [
      "DATASET",
      "DATASET_DESCRIPTION",
      "ORGANIZATION",
      "DIVISION",
      "DATA_SUBJECT_AREA_NAME",
      "DATA_ENTITY_NAME",
      "SYSTEM_NAME",
      "SYSTEM_TYPE",
      "INFORMATION_CLASS",
      "OWNER"
    ];

    this.displayNo = props.displayNo;
    this.changeOption = this.changeOption.bind(this);
    this.selectDownload = this.selectDownload.bind(this);
    this.changeFilterLogic = this.changeFilterLogic.bind(this);
    this.filterLogicOptions = ["All", "Any", "Any+"];
    this.filterLogicOption = 0;

    this.allDimensions = props.dimensions;
  }

  changeOption(e) {
    this.props.changeOption(e.target.selectedIndex);

    this.setState({ top: this.displayOptionsRows[e.target.selectedIndex] });
  }

  changeFilterLogic() {
    if (++this.filterLogicOption >= this.filterLogicOptions.length)
      this.filterLogicOption = 0;
    this.setState({
      filterLogic: this.filterLogicOptions[this.filterLogicOption]
    });
  }

  download(content, fileName, mimeType) {
    var a = document.createElement("a");
    mimeType = mimeType || "application/octet-stream";

    if (navigator.msSaveBlob) {
      // IE10
      navigator.msSaveBlob(
        new Blob([content], {
          type: mimeType
        }),
        fileName
      );
    } else if (URL && "download" in a) {
      //html5 A[download]
      a.href = URL.createObjectURL(
        new Blob([content], {
          type: mimeType
        })
      );
      a.setAttribute("download", fileName);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } else {
      window.location.href =
        "data:application/octet-stream," + encodeURIComponent(content); // only this mime type is supported
    }
  }

  getExportFileName() {
    let MyDate = new Date();

    return (
      "CatalogExplorerExport_" +
      MyDate.getFullYear() +
      ("0" + (MyDate.getMonth() + 1)).slice(-2) +
      ("0" + MyDate.getDate()).slice(-2) +
      "_" +
      ("0" + MyDate.getHours()).slice(-2) +
      ("0" + MyDate.getMinutes()).slice(-2) +
      ("0" + MyDate.getSeconds()).slice(-2)
    );
  }

  selectDownload() {
    this.download(
      d3.csvFormat(this.filter(this.props.data), [
        // "id",
        "DATASET",
        "DATASET_DESCRIPTION",
        "DATA_ENTITY_NAME",
        "DATA_SUBJECT_AREA_NAME",
        "INFORMATION_CLASS",
        "SYSTEM_NAME",
        "SYSTEM_TYPE",
        "ORGANIZATION",
        "DIVISION",
        "OWNER"
      ]),
      this.getExportFileName(),
      "text/csv;encoding:utf-8"
    );
    // console.log(
    // d3.csvFormat(this.state.data, [
    //   // "id",
    //   "DATASET",
    //   "DATASET_DESCRIPTION",
    //   "DATA_ENTITY_NAME",
    //   "DATA_SUBJECT_AREA_NAME",
    //   "SYSTEM_NAME",
    //   "SYSTEM_TYPE",
    //   "ORGANIZATION",
    //   "DIVISION",
    //   "OWNER"
    // ])
    // );
  }

  handleChange(e) {
    // console.log(e, e.target.id);
    let _filters = this.state.filters;

    _filters[e.target.id] = e.target.value;

    this.allDimensions[e.target.id].setLikeFilter(e.target.value);

    // console.log(_filters);

    let index = -1;

    this.setState({ filters: _filters });
  }

  highlightIndex(text, index, length, part) {
    // console.log(text, index, length, part);
    switch (part) {
      case 0:
        return text.substr(0, index);
      case 1:
        return text.substr(index, length);
      case 2:
        return text.substr(index + length, 999);

      default:
        return text;
    }

    let newString =
      text.substr(0, index) +
      "<b>" +
      text.substr(index, length) +
      "</b>" +
      text.substr(index + length, 999);
    // console.log(newString);
    return newString;
  }

  high(text, index, length) {
    if (index !== -1) {
      return (
        <div>
          {text.substr(0, index)}
          <span className="bg-warning">{text.substr(index, length)}</span>
          {text.substr(index + length, 999)}
        </div>
      );
    }
    return text;
  }

  high2(text, index, length) {
    if (index !== -1) {
      return (
        <div>
          {text.substr(0, index)}
          <strong>{text.substr(index, length)}</strong>
          {text.substr(index + length, 999)}
        </div>
      );
    }
    return text;
  }

  filter_value(string, match) {}

  filter2(input_array) {
    let _filters = this.state.filters.map(d => {
      return this.state.filterLogic === "Any+"
        ? this.state.filters[0].toUpperCase()
        : d.toUpperCase();
    });

    //console.log(_filters);

    let return_array = input_array.filter((d, ii) => {
      let filterMap = _filters.map((e, i) => {
        // if (ii < 10)
        //   console.log(
        //     "trying ",
        //     d[this.filterKeys[i]].toUpperCase(),
        //     " with ",
        //     e
        //   );
        //d.search[i].
        if (e === "") {
          d.search[i] = {
            index: -1,
            l: -1
          };
          return -1;
        }
        let index = d[this.filterKeys[i]].toUpperCase().indexOf(e);
        // console.log("index", index);
        if (index === -1) {
          d.search[i] = {
            index: -1,
            l: -1
          };
          return 0;
        }
        //console.log("match ", d[this.filterKeys[i]].toUpperCase(), " with ", e);
        d.search[i] = {
          index: index,
          l: e.length
        };
        return 1;
      });

      // if (ii < 10) {
      //   console.log(filterMap);
      // }

      let returnValue;

      if (this.state.filterLogic === "All")
        returnValue = filterMap.reduce((a, b) => a && (b === -1 ? 1 : b));

      if (this.state.filterLogic === "Any")
        returnValue = filterMap.reduce((a, b) => a || (b === -1 ? 0 : b));

      if (this.state.filterLogic === "Any+")
        returnValue = filterMap.reduce((a, b) => a || b);

      // if (ii < 10 || returnValue != 0) {
      //   console.log(filterMap, returnValue);
      // }
      return returnValue;
    });

    return return_array;
  }

  filter(input_array) {
    //let index = -1;
    let _filters = this.state.filters;

    return input_array.filter(d => {
      // d.search = new Array(8);
      //let filters = 0;
      if (
        (_filters[0] !== "") &
        (d.DATASET.toUpperCase().indexOf(_filters[0].toUpperCase()) === -1)
      ) {
        // if (this.state.filterLogic === "And")
        return false;
      } else {
        // if (d.DATASET.toUpperCase().indexOf(_filters[0].toUpperCase()) !== -1)
        //   filters++;
        d.search[0] = {
          index: d.DATASET.toUpperCase().indexOf(_filters[0].toUpperCase()),
          l: _filters[0].length
        };
      }

      if (
        (_filters[1] !== "") &
        (d.DATASET_DESCRIPTION.toUpperCase().indexOf(
          _filters[1].toUpperCase()
        ) ===
          -1)
      ) {
        // if (this.state.filterLogic === "And")
        return false;
      } else {
        // if (
        //   d.DATASET_DESCRIPTION.toUpperCase().indexOf(
        //     _filters[1].toUpperCase()
        //   ) !== -1
        // )
        //   filters++;
        d.search[1] = {
          index: d.DATASET_DESCRIPTION.toUpperCase().indexOf(
            _filters[1].toUpperCase()
          ),
          l: _filters[1].length
        };
      }

      if (
        (_filters[2] !== "") &
        (d.ORGANIZATION.toUpperCase().indexOf(_filters[2].toUpperCase()) === -1)
      )
        return false;
      else {
        d.search[2] = {
          index: d.ORGANIZATION.toUpperCase().indexOf(
            _filters[2].toUpperCase()
          ),
          l: _filters[2].length
        };
      }

      if (
        (_filters[3] !== "") &
        (d.DIVISION.toUpperCase().indexOf(_filters[3].toUpperCase()) === -1)
      )
        return false;
      else {
        d.search[3] = {
          index: d.DIVISION.toUpperCase().indexOf(_filters[3].toUpperCase()),
          l: _filters[3].length
        };
      }

      if (
        (_filters[4] !== "") &
        (d.DATA_SUBJECT_AREA_NAME.toUpperCase().indexOf(
          _filters[4].toUpperCase()
        ) ===
          -1)
      )
        return false;
      else {
        d.search[4] = {
          index: d.DATA_SUBJECT_AREA_NAME.toUpperCase().indexOf(
            _filters[4].toUpperCase()
          ),
          l: _filters[4].length
        };
      }

      if (
        (_filters[5] !== "") &
        (d.DATA_ENTITY_NAME.toUpperCase().indexOf(_filters[5].toUpperCase()) ===
          -1)
      )
        return false;
      else {
        d.search[5] = {
          index: d.DATA_ENTITY_NAME.toUpperCase().indexOf(
            _filters[5].toUpperCase()
          ),
          l: _filters[5].length
        };
      }
      if (
        (_filters[6] !== "") &
        (d.SYSTEM_NAME.toUpperCase().indexOf(_filters[6].toUpperCase()) === -1)
      )
        return false;
      else {
        d.search[6] = {
          index: d.SYSTEM_NAME.toUpperCase().indexOf(_filters[6].toUpperCase()),
          l: _filters[6].length
        };
      }

      if (
        (_filters[7] !== "") &
        (d.INFORMATION_CLASS.toUpperCase().indexOf(
          _filters[7].toUpperCase()
        ) ===
          -1)
      )
        return false;
      else {
        d.search[7] = {
          index: d.INFORMATION_CLASS.toUpperCase().indexOf(
            _filters[7].toUpperCase()
          ),
          l: _filters[7].length
        };
      }

      if (
        (_filters[8] !== "") &
        (d.SYSTEM_TYPE.toUpperCase().indexOf(_filters[8].toUpperCase()) === -1)
      )
        return false;
      else {
        d.search[8] = {
          index: d.SYSTEM_TYPE.toUpperCase().indexOf(_filters[8].toUpperCase()),
          l: _filters[8].length
        };
      }

      if (
        (_filters[9] !== "") &
        (d.OWNER.toUpperCase().indexOf(_filters[9].toUpperCase()) === -1)
      )
        return false;
      else {
        d.search[9] = {
          index: d.OWNER.toUpperCase().indexOf(_filters[9].toUpperCase()),
          l: _filters[9].length
        };
      }

      //   return false;
      // if (d.ORGANIZATION.indexOf(_filters[2]) === -1) return false;
      // if (d.DIVISION.indexOf(_filters[3]) === -1) return false;
      // if (d.DATA_SUBJECT_AREA_NAME.indexOf(_filters[4]) === -1) return false;
      // if (d.DATA_ENTITY_NAME.indexOf(_filters[5]) === -1) return false;
      // if (d.SYSTEM_NAME.indexOf(_filters[6]) === -1) return false;
      // if (d.SYSTEM_TYPE.indexOf(_filters[7]) === -1) return false;

      // if (filters) return true;
      // else return false;
      return true;
    });
  }

  render() {
    //console.log("                  DataSetTable Render");
    //const { data } = this.props;
    const data = this.filter2(this.props.data).slice(0, this.state.top);
    let comp = this;
    return (
      <div
        className="-"
        ref={anchor => (this.anchor = anchor)}
        style={{ overflowY: "scroll", overflowX: "hidden", height: "100vh" }}
      >
        Display
        <select onChange={this.changeOption} className="mr-1 ml-1">
          {comp.displayOptions.map((d, i) => (
            <option
              key={i}
              id={i}
              selected={comp.displayNo === i ? "selected" : ""}
            >
              {d}
            </option>
          ))}
        </select>
        # of Data Sets
        {/* <div onClick={this.selectDownload}>Download Data</div> */}
        <button
          type="button"
          onClick={this.selectDownload}
          className="btn btn-outline-success btn-sm ml-1"
        >
          Download Data
        </button>
        <table
          className="table table-sm"
          style={{
            tableLayout: "fixed",
            width: "100%",
            wordWrap: "break-word",
            fontSize: "0.8em"
          }}
        >
          <thead>
            <tr>
              <th scope="col" width="60px">
                #
              </th>
              <th scope="col">Data Set</th>
              <th scope="col">Data Set Description</th>
              <th scope="col">Organization</th>
              <th scope="col">Division</th>
              <th scope="col">Subject Area</th>
              <th scope="col">Entity</th>
              <th scope="col">Information Class</th>
              <th scope="col">System Type</th>
              <th scope="col">System Owner</th>
              <th scope="col">System</th>
            </tr>

            {this.state.filterLogic !== "Any+" ? (
              <tr>
                <th scope="col">
                  <button
                    type="button"
                    onClick={this.changeFilterLogic}
                    className="btn btn-outline-success btn-sm"
                  >
                    <small>{this.state.filterLogic}</small>
                  </button>
                </th>
                <th scope="col">
                  <input
                    id="0"
                    type="text"
                    placeholder="search..."
                    width="100%"
                    autoComplete="off"
                    value={this.state.filters[0]}
                    onChange={d => this.handleChange(d)}
                  />
                </th>
                <th scope="col">
                  <input
                    id="1"
                    type="text"
                    width="100%"
                    placeholder="search..."
                    value={this.state.filters[1]}
                    autoComplete="off"
                    onChange={d => this.handleChange(d)}
                  />
                </th>
                <th scope="col">
                  <input
                    id="2"
                    type="text"
                    width="100%"
                    value={this.state.filters[2]}
                    autoComplete="off"
                    placeholder="search..."
                    onChange={d => this.handleChange(d)}
                  />
                </th>
                <th scope="col">
                  <input
                    id="3"
                    type="text"
                    width="100%"
                    value={this.state.filters[3]}
                    placeholder="search..."
                    autoComplete="off"
                    onChange={d => this.handleChange(d)}
                  />
                </th>
                <th scope="col">
                  <input
                    id="4"
                    autoComplete="off"
                    type="text"
                    width="100%"
                    placeholder="search..."
                    value={this.state.filters[4]}
                    onChange={d => this.handleChange(d)}
                  />
                </th>
                <th scope="col">
                  <input
                    id="5"
                    type="text"
                    autoComplete="off"
                    width="100%"
                    placeholder="search..."
                    value={this.state.filters[5]}
                    onChange={d => this.handleChange(d)}
                  />
                </th>
                <th scope="col">
                  <input
                    id="7"
                    type="text"
                    autoComplete="off"
                    width="100%"
                    value={this.state.filters[7]}
                    placeholder="search..."
                    onChange={d => this.handleChange(d)}
                  />
                </th>
                <th scope="col">
                  <input
                    id="8"
                    type="text"
                    width="100%"
                    autoComplete="off"
                    placeholder="search..."
                    value={this.state.filters[8]}
                    onChange={d => this.handleChange(d)}
                  />
                </th>
                <th scope="col">
                  <input
                    id="9"
                    type="text"
                    autoComplete="off"
                    width="100%"
                    placeholder="search..."
                    value={this.state.filters[9]}
                    onChange={d => this.handleChange(d)}
                  />
                </th>
                <th scope="col">
                  <input
                    id="6"
                    type="text"
                    autoComplete="off"
                    width="100%"
                    value={this.state.filters[6]}
                    placeholder="search..."
                    onChange={d => this.handleChange(d)}
                  />
                </th>
              </tr>
            ) : (
              <tr>
                <th scope="col">
                  <button
                    type="button"
                    onClick={this.changeFilterLogic}
                    className="btn btn-outline-success btn-sm"
                  >
                    <small>{this.state.filterLogic}</small>
                  </button>
                </th>
                <th scope="col" colSpan="10">
                  <input
                    id="0"
                    type="text"
                    width="100%"
                    autoComplete="off"
                    size="100"
                    placeholder="Enter text to search..."
                    value={this.state.filters[0]}
                    onChange={d => this.handleChange(d)}
                  />
                </th>
              </tr>
            )}
          </thead>
          <tbody>
            {data.map((d, i) => {
              let renderString;

              return (
                <tr key={i}>
                  <th scope="row">{i + 1}</th>
                  <td>
                    {d.search[0].index !== -1
                      ? this.high(
                          d.DATASET,
                          d.search[0].index,
                          d.search[0].l,
                          0
                        )
                      : d.DATASET}
                  </td>
                  <td style={{ maxWidth: "250px" }}>
                    <small>
                      {d.search[1].index !== -1
                        ? this.high(
                            d.DATASET_DESCRIPTION,
                            d.search[1].index,
                            d.search[1].l,
                            0
                          )
                        : d.DATASET_DESCRIPTION}
                    </small>
                  </td>
                  <td>
                    {d.search[2].index !== -1
                      ? this.high(
                          d.ORGANIZATION,
                          d.search[2].index,
                          d.search[2].l,
                          0
                        )
                      : d.ORGANIZATION}
                  </td>
                  {/* <td>{d.ORGANIZATION}</td> */}
                  <td>
                    {d.search[3].index !== -1
                      ? this.high(
                          d.DIVISION,
                          d.search[3].index,
                          d.search[3].l,
                          0
                        )
                      : d.DIVISION}
                  </td>
                  {/* <td>{d.DIVISION}</td> */}
                  <td>
                    {d.search[4].index !== -1
                      ? this.high(
                          d.DATA_SUBJECT_AREA_NAME,
                          d.search[4].index,
                          d.search[4].l,
                          0
                        )
                      : d.DATA_SUBJECT_AREA_NAME}
                  </td>
                  {/* <td>{d.DATA_SUBJECT_AREA_NAME}</td> */}
                  <td>
                    {d.search[5].index !== -1
                      ? this.high(
                          d.DATA_ENTITY_NAME,
                          d.search[5].index,
                          d.search[5].l,
                          0
                        )
                      : d.DATA_ENTITY_NAME}
                  </td>
                  {/* <td>{d.DATA_ENTITY_NAME}</td> */}
                  <td>
                    {d.search[7].index !== -1
                      ? this.high(
                          d.INFORMATION_CLASS,
                          d.search[7].index,
                          d.search[7].l,
                          0
                        )
                      : d.INFORMATION_CLASS}
                  </td>
                  {/* <td>{d.SYSTEM_NAME}</td> */}
                  <td>
                    {d.search[8].index !== -1
                      ? this.high(
                          d.SYSTEM_TYPE,
                          d.search[8].index,
                          d.search[8].l,
                          0
                        )
                      : d.SYSTEM_TYPE}
                  </td>
                  {/* <td>{d.SYSTEM_TYPE}</td> */}
                  <td>
                    {d.search[9].index !== -1
                      ? this.high(d.OWNER, d.search[9].index, d.search[9].l, 0)
                      : d.OWNER}
                  </td>
                  <td>
                    {d.search[6].index !== -1
                      ? this.high(
                          d.SYSTEM_NAME,
                          d.search[6].index,
                          d.search[6].l,
                          0
                        )
                      : d.SYSTEM_NAME}
                  </td>
                </tr>
              );
              //console.log(renderString);

              /* {d.search[0].index !== -1
                    ? this.highlightIndex(
                        d.DATASET,
                        d.search[0].index,
                        d.search[0].l,0)}
                        + <b> + {
                        
                        this.highlightIndex(
                          d.DATASET,
                          d.search[0].index,
                          d.search[0].l,1)
                        }+</b>}
                      )
                    : d.DATASET} */

              /* </td>
                <td style={{ maxWidth: "250px" }}>
                  <small>{d.DATASET_DESCRIPTION}</small>
                </td>
                <td>{d.ORGANIZATION}</td>
                <td>{d.DIVISION}</td>
                <td>{d.DATA_SUBJECT_AREA_NAME}</td>
                <td>{d.DATA_ENTITY_NAME}</td>
                <td>{d.SYSTEM_NAME}</td>
                <td>{d.SYSTEM_TYPE}</td> */

              // </tr>
              return renderString;
            })}
          </tbody>
        </table>
      </div>
    );
  }
}
