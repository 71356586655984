import React, { Component } from "react";
import * as d3 from "d3";

import "./NestOptions.css";

const rectHeight = 20;
const rectPadding = 5;
const rectHeightTotal = rectHeight + rectPadding;
const margin = { top: 5, right: 15, bottom: 60, left: 0 };

export default class NestOptions extends Component {
  constructor(props) {
    super(props);
    //console.log("Legend:", props);
    this.resize = false;
    // If onClickNode function provided via props, call it when nodes are clicked/selected
    if (props.onClickNode) this.onClickNodeHandler = props.onClickNode;
    else this.onClickNodeHandler = false;

    this.createChart = this.createChart.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.dragged = this.dragged.bind(this);
    this.dragend = this.dragend.bind(this);
    this.dragstarted = this.dragstarted.bind(this);
    this.onResize = this.onResize.bind(this);
    this.x = this.x.bind(this);
    this.y = this.y.bind(this);

    this.dragging = false;

    this.dimensions = this.props.data.length;

    this.inUse = [];
    this.notInUse = [];

    props.data.forEach(d => {
      d.dragged = false;
      d.inUse ? this.inUse.push(d) : this.notInUse.push(d);
    });
    // console.log(this.inUse);
    // console.log(this.notInUse);
  }

  x(d) {
    return d[this.props.x];
  }

  y(d) {
    return d[this.props.y];
  }

  onResize() {
    // Set flag to resize = true to drive a re-draw in createChart
    this.resize = true;
    this.createChart(this.props.data);
  }

  componentDidMount() {
    // Add resize event handler to re-size and re-draw when window is resized
    window.addEventListener("resize", this.onResize, false);
    this.createChart(this.props.data);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize, false);
  }

  componentDidUpdate() {
    this.createChart(this.props.data);
  }

  handleClick(e) {
    // console.log(e, e.key, this.props.colorByDimension);
    this.props.handleClick(
      d3.event,
      e.key ? e.key : e,
      this.props.colorByDimension
    );
  }

  dragstarted(e, i, n) {
    //console.log("dragging started");
    //console.log(e, i, n);
    this.oldIndex = i;
    this.dragging = true;

    e.dragged = true;
    //this.dragged = e.currentTarget;
    //e.dataTransfer.effectAllowed = "move";
    //console.log(d3.select(this));
  }

  array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
  }

  array_remove(arr, index) {
    return arr.splice(index, 1);
  }

  dragged(e, i, n) {
    //console.log("DRAG Event");
    let newY = d3.event.y;
    //console.log(e, i, n);

    let switched = 0;

    //console.log(d3.event.sourceEvent.clientY);
    //console.log(d3.event.y);
    // console.log(this.dimensions + 1);

    if (d3.event.y >= this.dimensions * rectHeightTotal + margin.top) {
      // console.log("Not in use");

      // console.log(e);

      if (e.inUse === 1) {
        // console.log("switched");
        e.inUse = 0;
        switched = -1;
      } else {
        switched = 0;
      }
    }

    if (d3.event.y < this.dimensions * rectHeightTotal + margin.top) {
      //console.log("In use");

      //console.log(e);

      if (e.inUse === 0) {
        // console.log("switched");
        e.inUse = 1;
        switched = 1;
      } else {
        switched = 0;
      }
    }

    if (!e.inUse && d3.event.y < 0) newY = 0;

    // console.log(
    //   newY,
    //   (newY - 260) / 30,
    //   Math.floor((newY - 260) / 30),
    //   this.midPoint,
    //   this.notInUse.length - 1
    // );

    let newIndex = Math.min(
      Math.floor(
        e.inUse
          ? newY / rectHeightTotal
          : (newY - this.midPoint) / rectHeightTotal
      ),
      e.inUse ? this.inUse.length - 1 : this.notInUse.length - 1
    ); //this.dimensions - 1);

    if (switched == 1) {
      newIndex++;
    }

    if (newIndex < 0) newIndex = 0;

    // console.log(switched, this.oldIndex, newIndex);

    //console.log(this.over.offsetTop);
    d3.select(n[i])
      .attr("transform", "translate(" + 0 + "," + newY + ")")
      .attr("class", "draggedNestDimension")
      .select("rect")
      .attr("fill", d => (d.inUse ? "#004582" : "grey"));

    // console.log(this.notInUse);
    // console.log(this.inUse);

    if (newIndex != this.oldIndex || switched != 0) {
      if (switched === -1) {
        console.log(
          "moving index ",
          this.oldIndex,
          " : ",
          this.inUse[this.oldIndex],
          " from InUse to NotInUse"
        );

        this.notInUse.push(this.array_remove(this.inUse, this.oldIndex)[0]);
        // console.log("just switched");
        this.notInUse.forEach(d => console.log(d.name));
        this.oldIndex = this.notInUse.length - 1;
        // console.log(this.oldIndex);
      }

      if (switched === 1) {
        // console.log(
        //   "moving ",
        //   this.notInUse[this.oldIndex],
        //   " from NotInUse to InUse"
        // );
        this.inUse.push(this.array_remove(this.notInUse, this.oldIndex)[0]);
        this.oldIndex = this.inUse.length - 1;
      }

      if (switched === 0 && e.inUse) {
        // console.log(
        //   "moving in use ",
        //   this.inUse[this.oldIndex].name,
        //   " to new index:",
        //   newIndex
        // );
        this.array_move(this.inUse, this.oldIndex, newIndex);
        this.oldIndex = newIndex;
      }
      if (switched === 0 && !e.inUse) {
        // console.log(
        //   "moving not in use ",
        //   this.notInUse[this.oldIndex].name,
        //   " to new index:",
        //   newIndex
        // );
        this.array_move(this.notInUse, this.oldIndex, newIndex);
        this.oldIndex = newIndex;
      }

      //console.log(newIndex, this.oldIndex);
      //this.createChart(this.inUse);
      //console.log(this.inUse);

      if (e.inUse) {
        let naat = d3
          .select(this.svgref)
          .select("g.content")
          .selectAll(".nestDimension")
          .datum(this.inUse.filter(d => !d.dragged))
          .transition()
          .duration(350)
          .attr(
            "transform",
            (d, i) =>
              "translate(" +
              0 +
              "," +
              (i >= newIndex
                ? (i + 1) * rectHeightTotal
                : i * rectHeightTotal) +
              ")"
          );
      }
      if (!e.inUse || switched == 1) {
        let naat2 = d3
          .select(this.svgref)
          .select("g.content")
          .selectAll(".nestDimensionNotInUse")
          .datum(this.notInUse.filter(d => !d.dragged))
          .transition()
          .duration(350)
          .attr(
            "transform",
            (d, i) =>
              "translate(" +
              0 +
              "," +
              (i >= newIndex
                ? (i + 1) * rectHeightTotal + this.midPoint
                : i * rectHeightTotal + this.midPoint) +
              ")"
          );
      }
      // console.log(this.inUse.filter(d => !d.dragged));

      // naat.exit().remove();

      // naat;

      //   console.log(this.inUse);
    }
  }

  mouseOver(e) {
    //console.log(e);
  }

  dragend(e, i, n) {
    //console.log(e, i, n);
    this.dragging = false;
    e.dragged = false;
    d3.select(n[i]).attr("class", "nestDimension");
    this.createChart(this.inUse);
    this.props.nestUpdate(this.inUse);
  }

  createChart(chartData) {
    const anchor = this.anchor;
    const header = this.headerNode;

    const parentWidth = this.anchor.clientWidth;
    const parentHeight = this.anchor.clientHeight;

    // const margin = { top: 5, right: 15, bottom: 60, left: 0 };
    const width = parentWidth - margin.left - margin.right;
    const svgref = this.svgref;
    const node = this.svgref;

    // const rectHeight = 20;
    // const rectPadding = 5;
    // const rectHeightTotal = rectHeight + rectPadding;

    const x = this.x;
    const y = this.y;
    const series = this.props.series;

    const color = this.props.color;

    const colors = [
      "#ffffd9",
      "#edf8b1",
      "#c7e9b4",
      "#7fcdbb",
      "#41b6c4",
      "#1d91c0",
      "#225ea8",
      "#253494",
      "#081d58"
    ];
    const buckets = colors.length;

    const comp = this;

    d3.select(node)
      .style("width", parentWidth)
      .style("height", this.dimensions * rectHeightTotal * 2 + 20 + margin.top);

    this.midPoint = this.dimensions * rectHeightTotal + margin.top;

    // console.log(this.midPoint);
    d3.select(node)
      .select("g.content")
      .remove();

    const g = d3
      .select(node)
      .selectAll("g.content")
      .data([0])
      .enter()
      .append("g")
      .attr("class", "content")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    g.append("line") // attach a line
      .style("stroke", "black") // colour the line
      .attr("x1", 0) // x position of the first end of the line
      .attr("y1", this.midPoint) // y position of the first end of the line
      .attr("x2", width) // x position of the second end of the line
      .attr("y2", this.midPoint);

    //console.log(chartData);

    const yScale = d3
      .scaleBand()
      .domain(
        this.props.colorByGroup
          ? this.props.colorByGroup
          : [{ key: "Dataset" }].map(d => d.key)
      )
      .range([0, (this.props.colorByGroup.length + 1) * 20 + margin.top])
      .padding(0.1);

    g.selectAll(".nestDimension").remove();

    //let legend = g.selectAll(".nestDimension").data(chartData);
    let legend = g.selectAll(".nestDimension").data(this.inUse);
    legend.exit().remove();

    //let drag = d3.behavior.drag();

    let nestDims = legend
      .enter()
      .append("g")
      .attr("class", "nestDimension")
      .attr(
        "transform",
        (d, i) => "translate(" + 0 + "," + i * rectHeightTotal + ")"
      )
      .call(
        d3
          .drag()
          .on("start", this.dragstarted)
          .on("drag", this.dragged)
          .on("end", this.dragend)
      )
      .on("mouseover", this.mouseOver);

    nestDims
      .append("rect")
      .attr("rx", 6)
      .attr("ry", 6)
      .attr("x", 0)
      .attr("y", (d, i) => {
        return 0 * 30;
      })
      .attr("width", 150)
      .attr("height", rectHeight)
      .attr("fill", "#004582")
      .attr("style", "cursor:pointer");
    //.on("click", d => this.handleClick(d));

    nestDims
      .append("text")
      .attr("y", 15)
      .attr("x", 10)
      //.attr("stroke", "white")
      .attr("fill", "white")
      .style("font", "11px sans-serif")
      .text(d => d.name);

    //let legend = g.selectAll(".nestDimension").data(chartData);

    // let gNotUsed = d3
    //   .select(node)
    //   .selectAll("g.contentNotUsed")
    //   .data([0])
    //   .enter()
    //   .append("g")
    //   .attr("class", "contentNotUsed")
    //   .attr(
    //     "transform",
    //     "translate(" +
    //       margin.left +
    //       "," +
    //       (margin.top + (this.dimensions + 1) * 30 + 50) +
    //       ")"
    //   );

    // console.log(this.notInUse);

    let legend2 = g.selectAll(".nestDimensionNotInUse").data(this.notInUse);

    legend2.exit().remove();

    //let drag = d3.behavior.drag();

    let nestDims2 = legend2
      .enter()
      .append("g")
      .attr("class", "nestDimensionNotInUse")
      .attr(
        "transform",
        (d, i) =>
          "translate(" + 0 + "," + (i * rectHeightTotal + this.midPoint) + ")"
      )
      .call(
        d3
          .drag()
          .on("start", this.dragstarted)
          .on("drag", this.dragged)
          .on("end", this.dragend)
      )
      .on("mouseover", this.mouseOver);

    nestDims2
      .append("rect")
      .attr("rx", 6)
      .attr("ry", 6)
      .attr("x", 0)
      .attr("y", (d, i) => {
        return 0 * rectHeightTotal;
      })
      .attr("width", 150)
      .attr("height", rectHeight)
      .attr("fill", "grey")
      .attr("style", "cursor:pointer");
    //.on("click", d => this.handleClick(d));

    nestDims2
      .append("text")
      .attr("y", 15)
      .attr("x", 10)
      //.attr("stroke", "white")
      .style("font", "11px sans-serif")
      .attr("fill", "white")
      .text(d => d.name);

    // let legend = g.selectAll("text").data(
    //   this.props.colorByGroup
    //     ? this.props.colorByGroup
    //     : // .filter(
    //       //     d => comp.props.colorByDimension.members[d].filtered
    //       //   )
    //       [{ key: "Dataset" }],
    //   d => d.key
    // );

    //legend.enter().append("text", d => d.name);

    // legend
    //   .enter()
    //   .append("text")
    //   .attr("class", "label")
    //   .attr("style", "cursor:pointer")
    //   .on("click", d => this.handleClick(d))
    //   .text(function(d) {
    //     return (
    //       d.key +
    //       " - " +
    //       d.value +
    //       " (" +
    //       ((d.value / comp.props.total) * 100).toFixed(1) +
    //       "%)"
    //     );
    //   })
    //   .attr("x", 0)
    //   //.attr("y", d => yScale(d.key));
    //   .attr("y", (d, i) => {
    //     return i * 20 + 10;
    //   });
  }

  render() {
    return (
      <div
        className="Legend"
        ref={anchor => (this.anchor = anchor)}
        style={{ overflowY: "visible", overflowX: "visible" }}
      >
        <svg
          ref={svgref => (this.svgref = svgref)}
          width={this.width}
          height={this.height}
          overflow="visible"
        />
      </div>
    );
  }
}
