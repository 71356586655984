import React, { Component } from "react";
import * as d3 from "d3";
import * as d3Fetch from "d3-fetch";
import "./App.css";

import HorizontalBarChart from "./Components/HorizontalBarChart";
import BubbleChart from "./Components/BubbleChart";
import Legend from "./Components/Legend";
import NodeDetails from "./Components/NodeDetails";
import BubbleChartControl from "./Components/BubbleChartControl";
import DataSetTable from "./Components/DataSetTable";
import VerticalTree from "./Components/VerticalTree";

import NestOptions from "./Components/NestOptions";
import NavBar from "./Components/NavBar";
import AuthContext from '../../context/authContext.js';

import {
  createDimension,
  initCrossFilter,
  searchdims,
  listalldims
} from "./Util/DataManager";

class CatalogExplorer extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);

    this.state = {
      xf: null,
      isLoading: true,
      group1: null,
      group2: null,
      group3: null,
      color: null,
      sortBy: 0,
      colorBy: 0,
      clusterBy: 0,
      layoutBy: 0,
      displayNo: 1,
      action: "",
      selectedDimension: null,
      zoomFunction: null
    };

    this.scrollLocation = 1;

    this.colorBy = ["NONE", "SYSTEM_TYPE"];
    this.displayOptions = ["None", "25", "50", "100", "250", "500", "All"];
    this.displayOptionsRows = [0, 25, 50, 100, 250, 500, "Infinity"];
    this.sortKeys = ["NONE", "SYSTEM_TYPE", "ORGANIZATION", "DIVISION"];

    this.nestDimensionOptions = [
      { name: "Division", key: "DIVISION", index: 0, inUse: 0, inUseOrder: 0 },
      {
        name: "Organization",
        key: "ORGANIZATION",
        index: 1,
        inUse: 0,
        inUseOrder: 0
      },
      {
        name: "Data Subject Area",
        key: "DATA_SUBJECT_AREA_NAME",
        index: 2,
        inUse: 0,
        inUseOrder: 0
      },
      {
        name: "Data Entity",
        key: "DATA_ENTITY_NAME",
        index: 3,
        inUse: 0,
        inUseOrder: 0
      },
      { name: "Data Set", key: "DATASET", index: 4, inUse: 0, inUseOrder: 0 },
      { name: "System", key: "SYSTEM_NAME", index: 5, inUse: 0, inUseOrder: 0 },
      {
        name: "System Type",
        key: "SYSTEM_TYPE",
        index: 6,
        inUse: 0,
        inUseOrder: 0
      },
      {
        name: "Information Class",
        key: "INFORMATION_CLASS",
        index: 7,
        inUse: 0,
        inUseOrder: 0
      },
      { name: "Owner", key: "OWNER", index: 8, inUse: 0, inUseOrder: 0 }
    ];

    this.appState = {
      viewBy: ["Organization"],
      nestBy: ["Organization"]
    };

    this.nestDimensions = [];

    this.setAppStateFromQuery();

    //if (this.appState.nestBy)

    this.updateNestDimensionOptionsFromAppState();

    // this.nestDimensions = this.nestDimensionOptions.filter(d => d.inUse === 1);

    this.handleClick = this.handleClick.bind(this);
    this.handleBubbleControl = this.handleBubbleControl.bind(this);
    this.clickNode = this.clickNode.bind(this);
    this.scrollToDataSet = this.scrollToDataSet.bind(this);
    this.changeDisplayOption = this.changeDisplayOption.bind(this);
    this.resetFilters = this.resetFilters.bind(this);
    this.nestUpdate = this.nestUpdate.bind(this);
    this.changeFacetOption = this.changeFacetOption.bind(this);
    this.searchStructure = this.searchStructure.bind(this);
    this.updateVisualization = this.updateVisualization.bind(this);
    this.registerZoomFunction = this.registerZoomFunction.bind(this);
    // this.updateNestDimensionOptionsFromAppState = this.updateNestDimensionOptionsFromAppState.bind(
    //   this
    // );
  }

  registerZoomFunction(d) {
    // console.log("register zoom function", d);
    this.setState({ zoomFunction: d });
    this.forceUpdate();
  }

  zoomToNode(d) {
    if (this.state.zoomFunction) this.state.zoomFunction(d);
  }

  searchStructure(str) {
    let res = [];
    if (str)
      this.dataRoot.descendants().forEach(d => {
        if (d.data.key.toUpperCase().indexOf(str.toUpperCase()) > -1) {
          res.push(d);
        }
      });
    return res;
  }

  updateNestDimensionOptionsFromAppState() {
    // console.log(this.appState);
    this.nestDimensions = [];
    let found = 0;
    this.appState.nestBy.forEach((d, i) =>
      this.nestDimensionOptions.forEach(e => {
        if (e.name === d) {
          //console.log("found", d);
          e.inUse = 1;
          found = 1;
          e.inUseOrder = i + 1;
          this.nestDimensions.push(e);
        }
      })
    );

    this.nestDimensionOptions = this.nestDimensionOptions.sort((a, b) => {
      // console.log(
      //   "comparing ",
      //   a.inUseOrder,
      //   b.inUseOrder,
      //   b.inUseOrder - a.inUseOrder
      // );
      return a.inUseOrder - b.inUseOrder;
    });
    // console.log(this.nestDimensionOptions);
  }

  // updateViewByFromAppState() {
  //   this.setState({

  //   })
  // }

  setAppStateFromQuery() {
    // console.log("AppState = ", this.appState);
    let queryVariables = this.getAllQueryVariables();
    // console.log("Queryvariables", queryVariables);
    Object.keys(this.appState).forEach(d => {
      if (queryVariables[d]) this.appState[d] = queryVariables[d];
    });
    // console.log("AppState = ", this.appState);
  }

  //move to senf utils
  pushAppStateToQuery() {
    // console.log("Push appstate to query");
    let hash = "";

    for (var key in this.appState) {
      // console.log(key);
      if (this.appState.hasOwnProperty(key)) {
        if (hash !== "") hash = hash + "!";
        hash = hash + key + "=";

        this.appState[key].forEach(
          (d, i) => (hash += d + (i < this.appState[key].length - 1 ? "|" : ""))
        );
      }
    }
    //window.location.hash = encodeURIComponent(hash);
    window.history.replaceState(null, null, "#" + encodeURIComponent(hash));
    //console.log(hash);
  }

  getAllQueryVariables() {
    var allvars = {};
    var query = decodeURIComponent(window.location.hash.substring(1));
    if (query) {
      var vars = query.split("!");
      if (vars) {
        for (var i = 0; i < vars.length; i++) {
          var pair = vars[i].split("=");
          var objs = pair[1].split("|");
          allvars[pair[0]] = objs;
        }
      }
    }
    return allvars;
  }

  nestUpdate(e) {
    this.updateDataNesting(e);
    this.nestDimensions = e;
    this.appState.nestBy = e.map(d => d.name);
    this.pushAppStateToQuery();
    this.forceUpdate();
  }

  changeDisplayOption(o) {
    this.appState.displayNo = [o];
    this.pushAppStateToQuery();
  }

  handleBubbleControl(group, option) {
    let obj = { action: "BUBBLE_CONTROL_CHANGE" };
    if (group === 0) {
      obj.colorBy = option;
      this.updateColorBy(option);
    }
    if (group === 1) {
      obj.clusterBy = option;
      if (option == 1) obj.clusterByDim = this.dim1;
      if (option == 2) obj.clusterByDim = this.dim2;
      if (option == 3) obj.clusterByDim = this.dim5;
    }
    if (group === 2) obj.layoutBy = option;
    if (group === 3) obj.layoutBy = option;
    if (group === 4) {
      if (this.state.sortBy === option) obj.sortBy = -option;
      else obj.sortBy = option;

      this.sortIndex = {};

      if (option === 1)
        this.group1
          .top(Infinity)
          .forEach(d => (this.sortIndex[d.key] = d.value));
      if (option === 2)
        this.group2
          .top(Infinity)
          .forEach(d => (this.sortIndex[d.key] = d.value));
      if (option === 3)
        this.group5
          .top(Infinity)
          .forEach(d => (this.sortIndex[d.key] = d.value));

      let comp = this;

      this.sortReset = 0;

      this.sortFunction = function(a, b) {
        if (comp.state.sortBy > 0) {
          comp.sortReset = 0;
          return (
            comp.sortIndex[b.data[comp.sortKeys[comp.state.sortBy]]] -
            comp.sortIndex[a.data[comp.sortKeys[comp.state.sortBy]]]
          );
        }
        if (comp.state.sortBy < 0) {
          comp.sortReset = 0;
          return (
            comp.sortIndex[a.data[comp.sortKeys[-comp.state.sortBy]]] -
            comp.sortIndex[b.data[comp.sortKeys[-comp.state.sortBy]]]
          );
        }
        comp.sortReset = 0;
        return 0;
      };
    }

    this.setState(obj);
  }

  updateColorBy(option) {
    if (option === 0) {
      this.colorScale = d3.scaleOrdinal().range(["grey"]);

      this.color = function(d) {
        return "grey";
      };

      this.colorByGroup = [
        { key: "Data Set", value: this.mainDim.top(Infinity).length }
      ];

      this.colorByDimension = {
        members: { "Data Set": { filtered: 0 } },
        setFilter: d => null,
        filtered: 0,
        group: function() {
          return {
            top: function(e) {
              return [{ key: "Data Set" }];
            }
          };
        }
      };
    }

    if (option === 1) {
      this.system_typeIndex = {};
      this.system_types = this.group1.all();
      this.system_types.forEach((d, i) => (this.system_typeIndex[d] = i));

      this.colorScale = d3
        .scaleOrdinal()
        .range([
          "#cfff9e",
          "#a56dff",
          "#59da00",
          "#001e7f",
          "#ffd632",
          "#670050",
          "#01fad2",
          "#cd014c",
          "#00a353",
          "#ff66a2",
          "#a19100",
          "#001f3b",
          "#ff9769",
          "#007882",
          "#9e4200",
          "#a7faff",
          "#ffe0b8"
        ]);

      let self = this;

      this.color = function(d) {
        return self.colorScale(d.data.SYSTEM_TYPE);
      };

      this.colorByDimension = this.dim1;
      this.colorByGroup = this.group1.top(Infinity);
    }

    if (option === 2) {
      this.system_typeIndex = {};
      this.system_types = this.group2.all();
      this.system_types.forEach((d, i) => (this.system_typeIndex[d] = i));

      this.colorScale = d3
        .scaleOrdinal()
        .range([
          "#cfff9e",
          "#a56dff",
          "#59da00",
          "#001e7f",
          "#ffd632",
          "#670050",
          "#01fad2",
          "#cd014c",
          "#00a353",
          "#ff66a2",
          "#a19100",
          "#001f3b",
          "#ff9769",
          "#007882",
          "#9e4200",
          "#a7faff",
          "#ffe0b8"
        ]);

      let self = this;

      this.color = function(d) {
        return self.colorScale(d.data.ORGANIZATION);
      };

      this.colorByDimension = this.dim2;
      this.colorByGroup = this.group2.top(Infinity);
    }

    if (option === 3) {
      this.system_typeIndex = {};
      this.system_types = this.group5.all();
      this.system_types.forEach((d, i) => (this.system_typeIndex[d] = i));

      this.colorScale = d3
        .scaleOrdinal()
        .range([
          "#cfff9e",
          "#a56dff",
          "#59da00",
          "#001e7f",
          "#ffd632",
          "#670050",
          "#01fad2",
          "#cd014c",
          "#00a353",
          "#ff66a2",
          "#a19100",
          "#001f3b",
          "#ff9769",
          "#007882",
          "#9e4200",
          "#a7faff",
          "#ffe0b8"
        ]);

      let self = this;

      this.color = function(d) {
        return self.colorScale(d.data.DIVISION); //CHANGE to division
      };

      this.colorByDimension = this.dim5;
      this.colorByGroup = this.group5.top(Infinity);
    }

    this.setState({
      color: this.color,
      colorScale: this.colorScale,
      colorByGroup: this.colorByGroup,
      colorByDimension: this.colorByDimension
    });
  }

  componentDidMount() {
    const authTokens = this.context.authTokens;
    const apiAddress = process.env.REACT_APP_POSTGRES_API_URL;
    let debug = false;
    let comp = this;
    this.setState({ isLoading: true });
    this.offline = false;
    window.d3Fetch = d3Fetch;

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${authTokens}`,
      'Google-Token': authTokens,
    };

    const reqObject = {
      method: 'GET',
      headers,
      credentials: 'include',
      mode: 'cors',
    };

    d3Fetch.json(`${apiAddress}catalogexplorer`, reqObject)
      .catch(function(err) {
        comp.offline = true;
        return (
          {
            error: 'cannot fetch data from postgres api'
          }
        );
      })
      .then(function(data) {
        window.fres = data;

        if (!data.data) return data;
        let newData = [];

        data.data.forEach(d => {
          let obj = {};
          data.columns.forEach((k, i) => {
            obj[k] = d[i];
          });
          newData.push(obj);
        });

        return newData;
      })
      .then(function(data) {
        if (debug) console.log(data[0]);

        let i = 0;

        for (; i < data.length; i++) {
          data[i].id = i;
          data[i].search = new Array(10).fill({ index: -1, l: 0 });
        }
        window.data = data;
        return data;
      })
      .then(data => initCrossFilter(data))
      .then(cf => {
        window.xf = cf;
        this.cf = cf;
        this.dim1 = createDimension("System Type", d => d.SYSTEM_TYPE, true);
        this.group1 = this.dim1.createGroup();
        this.dim2 = createDimension("Organization", d => d.ORGANIZATION, true);
        this.group2 = this.dim2.createGroup();
        this.dim3 = createDimension("System Name", d => d.SYSTEM_NAME, true);
        this.group3 = this.dim3.createGroup();
        this.dim4 = createDimension(
          "Data Entity",
          d => d.DATA_ENTITY_NAME,
          true
        );
        this.group4 = this.dim4.createGroup();
        this.dim5 = createDimension("Division", d => d.DIVISION, true); // CHANGE TO DIVISION
        this.group5 = this.dim5.createGroup();
        this.dim6 = createDimension(
          "Data Subject Area",
          d => d.DATA_SUBJECT_AREA_NAME,
          true
        ); // CHANGE TO DIVISION
        this.group6 = this.dim6.createGroup();

        this.dim7 = createDimension("Owner", d => d.OWNER, true);
        this.group7 = this.dim7.createGroup();

        this.dim8 = createDimension(
          "Information Class",
          d => d.INFORMATION_CLASS,
          true
        );
        this.group8 = this.dim8.createGroup();

        this.dim9 = createDimension("Data Set", d => d.DATASET, true);
        this.group9 = this.dim9.createGroup();
        this.dim10 = createDimension(
          "Data Set Description",
          d => d.DATASET_DESCRIPTION,
          true
        );
        this.group10 = this.dim10.createGroup();

        this.mainDim = createDimension("Dataset", d => d.DATASET);

        this.dimensions = [];

        this.dimensions.push({
          key: "Division",
          dim: this.dim5,
          group: this.group5
        });

        this.dimensions.push({
          key: "Organization",
          dim: this.dim2,
          group: this.group2
        });

        this.dimensions.push({
          key: "Data Entity",
          dim: this.dim4,
          group: this.group4
        });

        this.dimensions.push({
          key: "Data Subject Area",
          dim: this.dim6,
          group: this.group6
        });

        this.dimensions.push({
          key: "System Type",
          dim: this.dim1,
          group: this.group1
        });

        this.dimensions.push({
          key: "System",
          dim: this.dim3,
          group: this.group3
        });

        this.dimensions.push({
          key: "Owner",
          dim: this.dim7,
          group: this.group7
        });

        this.dimensions.push({
          key: "Information Class",
          dim: this.dim8,
          group: this.group8
        });

        this.selectedDimension = 1;

        this.allDimensions = [];
        this.allDimensions.push(
          this.dim9,
          this.dim10,
          this.dim2,
          this.dim5,
          this.dim6,
          this.dim4,
          this.dim8,
          this.dim1,
          this.dim7,
          this.dim3
        );

        window.ad = this.allDimensions;

        window.listalldims = listalldims;
        window.search = searchdims;

        /* End Setup */

        /* Begin rehydrate state from url */

        /* End rehydrate state from url */

        this.total = this.mainDim.top(Infinity).length;

        // this.colorBy = [
        //   { name: "None", key: null },
        //   { name: "System Type", key: "SYSTEM_TYPE" }
        // ];

        // this.sortIndex = {};

        // this.group1
        //   .top(Infinity)
        //   .forEach(d => (this.sortIndex[d.key] = d.value));

        let comp = this;

        // this.sortFunction = function(a, b) {
        //   return 0;
        // };

        this.updateColorBy(0);

        this.updateDataNesting(this.nestDimensions);

        // window.color = this.color;
        // window.colorScale = this.colorScale;

        window.dim1 = this.dim1;
        window.group1 = this.group1;
        window.dim2 = this.dim2;
        window.group2 = this.group2;
        window.dim3 = this.dim3;
        window.group3 = this.group3;
        window.dim4 = this.dim4;
        window.group4 = this.group4;
        window.dim5 = this.dim5;
        window.group5 = this.group5;

        window.mainDim = this.mainDim;

        window.d3 = d3;

        this.setState({
          // xf: cf,
          isLoading: false,
          group1: this.group1.top(Infinity),
          group2: this.group2.top(Infinity),
          group3: this.group3.top(Infinity),
          group4: this.group4.top(Infinity),
          group5: this.group5.top(Infinity),
          group6: this.group6.top(Infinity),
          group7: this.group7.top(Infinity),
          group8: this.group8.top(Infinity),
          group9: this.group9.top(Infinity),
          group10: this.group10.top(Infinity),
          mainDim: this.mainDim.top(Infinity),
          // color: this.color,
          // colorScale: this.colorScale,
          selectedDimension: this.dimensions[this.selectedDimension].dim,
          selectedGroup: this.dimensions[this.selectedDimension].group.top(
            Infinity
          )
        });
      });
  }

  // colorByGroup: this.colorByGroup

  updateDataNesting(e) {
    // console.log("updating data nesting", e);
    let nest = d3.nest();

    e.forEach(d => nest.key(e => e[d.key]));

    //.key(d => d.DIVISION)
    //.key(d => d.ORGANIZATION)
    //.key(d => d.DATA_SUBJECT_AREA_NAME)
    //.key(d => d.DATA_ENTITY_NAME)
    //.key(d => d.DATASET)
    //.key(d => d.SYSTEM_NAME)
    //nest.rollup(d => d.length);
    this.dataNest = nest.entries(this.mainDim.top(Infinity));

    window.dataNest = this.dataNest;

    let swapValuestoChildren = function(d) {
      if (d.values) {
        d.children = d.values;
        d.values = null;
        d.children.forEach(e => swapValuestoChildren(e));
      }
      // } else {
      //   //console.log(d);
      //   d.key = d.DATASET;
      //   d._children = d.children;
      //   d.children = null;
      // }
    };

    this.dataNest.forEach(d => swapValuestoChildren(d));

    this.dataRoot = d3.hierarchy({
      key: "Agency",
      children: this.dataNest
    });

    //console.log(this.dataRoot);

    let collapseDatasets = function(d) {
      if (d.height === 1) {
        d._children = d.children;
        delete d.children; // = null;
        d.datasets = d._children.length;

        // d.dataset_percent = d._children.length / d.parent.children.length;
        //console.log(d.parent);
        //console.log("reached bottom, returning ", d._children.length);
        return d._children.length;
        //return d._children.length;
      } else {
        //console.log(d);
        let s = d.children.map(e => collapseDatasets(e));
        //console.log(s);
        d.datasets = d3.sum(s);
        // d.dataset_percent = d.parent
        //   ? d.children.length / d.parent.chilren.length
        //   : 1;
        // console.log(d);
        return d.datasets;
      }
    };

    let calcStats = function(d) {
      d.dataset_percent = d.parent
        ? Math.round((d.datasets / d.parent.datasets) * 10000) / 100
        : 100;
      if (d.children) d.children.forEach(calcStats);
    };

    //console.log(this.dataRoot);
    collapseDatasets(this.dataRoot);

    calcStats(this.dataRoot);

    window.dataRoot = this.dataRoot;
    //this.forceUpdate();
  }

  resetFilters() {
    this.dim1.resetFilters();
    this.dim2.resetFilters();
    this.dim3.resetFilters();
    this.dim4.resetFilters();
    this.dim5.resetFilters();
    this.dim6.resetFilters();
    this.dim7.resetFilters();
    this.dim8.resetFilters();
    this.dim9.resetFilters();
    this.dim10.resetFilters();
    this.setState({
      group1: this.group1.top(Infinity),
      group2: this.group2.top(Infinity),
      group3: this.group3.top(Infinity),
      group4: this.group4.top(Infinity),
      group5: this.group5.top(Infinity),
      group6: this.group6.top(Infinity),
      group7: this.group7.top(Infinity),
      group8: this.group8.top(Infinity),
      group9: this.group9.top(Infinity),
      group10: this.group10.top(Infinity),
      mainDim: this.mainDim.top(Infinity),
      colorByGroup: this.colorByDimension
        ? this.colorByDimension.group().top(Infinity)
        : null,
      action: "RESETFILTER",
      // selectedDimension: this.dimensions[ this.selectedDimension].dim,
      selectedGroup: this.dimensions[this.selectedDimension].group.top(
        Infinity
      ),
      filterApplied:
        this.dim1.filterApplied ||
        this.dim2.filterApplied ||
        this.dim3.filterApplied ||
        this.dim4.filterApplied ||
        this.dim5.filterApplied ||
        this.dim6.filterApplied ||
        this.dim7.filterApplied ||
        this.dim8.filterApplied ||
        this.dim9.filterApplied ||
        this.dim10.filterApplied
    });
    this.updateDataNesting(this.nestDimensions);
    this.total = this.mainDim.top(Infinity).length;
    //this.nestDimensions = e;
  }

  updateVisualization() {
    this.updateDataNesting(this.nestDimensions);
    this.total = this.mainDim.top(Infinity).length;
    this.setState({
      group1: this.group1.top(Infinity),
      group2: this.group2.top(Infinity),
      group3: this.group3.top(Infinity),
      group4: this.group4.top(Infinity),
      group5: this.group5.top(Infinity),
      group6: this.group6.top(Infinity),
      group7: this.group7.top(Infinity),
      group8: this.group8.top(Infinity),
      mainDim: this.mainDim.top(Infinity),
      colorByGroup: this.colorByDimension
        ? this.colorByDimension.group().top(Infinity)
        : null,
      action: "FILTER",
      selectedDimension: this.dimensions[this.selectedDimension].dim,
      selectedGroup: this.dimensions[this.selectedDimension].group.top(
        Infinity
      ),
      filterApplied:
        this.dim1.filterApplied ||
        this.dim2.filterApplied ||
        this.dim3.filterApplied ||
        this.dim4.filterApplied ||
        this.dim5.filterAppliedd ||
        this.dim6.filterApplied ||
        this.dim7.filterApplied ||
        this.dim8.filterApplied ||
        this.dim9.filterApplied ||
        this.dim10.filterApplied
    });
  }

  handleClick(ev, e, dimension) {
    //console.log(ev, e, dimension);
    if (ev.shiftKey) {
      document.getSelection().removeAllRanges();
      dimension.toggleFilter(e);
    } else {
      dimension.setFilter(e);
    }

    //this.group1.top(Infinity).forEach(d => (this.sortIndex[d.key] = d.value));
    this.updateDataNesting(this.nestDimensions);
    this.total = this.mainDim.top(Infinity).length;
    this.setState({
      group1: this.group1.top(Infinity),
      group2: this.group2.top(Infinity),
      group3: this.group3.top(Infinity),
      group4: this.group4.top(Infinity),
      group5: this.group5.top(Infinity),
      group6: this.group6.top(Infinity),
      group7: this.group7.top(Infinity),
      group8: this.group8.top(Infinity),
      mainDim: this.mainDim.top(Infinity),
      colorByGroup: this.colorByDimension
        ? this.colorByDimension.group().top(Infinity)
        : null,
      action: "FILTER",
      filterApplied:
        this.dim1.filterApplied ||
        this.dim2.filterApplied ||
        this.dim3.filterApplied ||
        this.dim4.filterApplied ||
        this.dim5.filterAppliedd ||
        this.dim6.filterApplied ||
        this.dim7.filterApplied ||
        this.dim8.filterApplied ||
        this.dim9.filterApplied ||
        this.dim10.filterApplied
    });
  }

  clickNode_(e) {
    this.setState({
      clickedNode: e,
      action: "CLICKED_NODE"
    });
  }

  clickNode(d) {
    if (d.children) {
      d._children = d.children;
      delete d.children; // = null;
    } else {
      d.children = d._children;
      delete d._children; // = null;
    }
    this.forceUpdate();
  }

  scrollToDataSet() {
    if (this.scrollLocation) {
      window.scrollTo(0, this.dataSetTableRef.offsetTop - 60);
      this.scrollLocation = 1 - this.scrollLocation;
    } else {
      window.scrollTo(0, 0);
      this.scrollLocation = 1 - this.scrollLocation;
      this.updateVisualization();
    }
  }

  changeFacetOption(e) {
    this.selectedDimension = e.target.selectedIndex;
    this.appState.viewBy = [this.dimensions[this.selectedDimension].dim.name];
    this.pushAppStateToQuery();
    this.setState({
      selectedDimension: this.dimensions[this.selectedDimension].dim,
      selectedGroup: this.dimensions[this.selectedDimension].group.top(Infinity)
    });
  }

  scrollToTop() {
    window.scrollTo(0, 0);
  }

  render() {
    const { isLoading, sortBy, colorBy, clusterBy, layoutBy } = this.state;
    const styleFix = {
      'marginTop': '60px',
    };

    if (isLoading) {
      return <p>Loading ...</p>;
    }

    return (
      <div className="App">
        <NavBar
          scrollDown={this.scrollToDataSet}
          scrollPos={this.scrollLocation}
          filterApplied={this.state.filterApplied}
          resetFilters={this.resetFilters}
          offline={this.offline}
          total={this.total}
          updateVisualization={this.updateVisualization}
          searchFunction={searchdims}
          searchStructure={this.searchStructure}
          zoomToNode={this.state.zoomFunction}
        />
        <div className="container-fluid">
          {/* <div className="row">
            <div className="col-sm-1">
              <strong>By Application Type</strong>
            </div>
            <div className="col-sm-11">
              <StatCard
                data={this.state.group1}
                handleClick={this.handleClick}
                dimension={this.dim1}
              />
            </div>
          </div>
          {/* <div className="row">
            <div className="col-sm-12">
              <hr />
            </div>
          </div> */}
          <div className="row" style={styleFix}>
            <div className="col-sm-3">
              <strong>View By </strong>

              {/* <div
                className="btn-group btn-group-sm mr-1"
                role="group"
                aria-label="First group"
              >
                {this.dimensions.map((d, i) => (
                  <button
                    key={i}
                    type="button"
                    className={
                      i === sortBy || i == -sortBy
                        ? "btn btn-success btn-sm"
                        : "btn btn-secondary btn-sm"
                    }
                    // onClick={d => handleChange(4, i)}
                  >
                    <small>{d.key}</small>
                  </button>
                ))}
              </div> */}

              <select onChange={this.changeFacetOption}>
                {this.dimensions.map((d, i) => (
                  <option
                    key={i}
                    id={i}
                    selected={i === this.selectedDimension ? "selected" : ""}
                  >
                    {d.key}
                  </option>
                ))}
              </select>

              <div style={{ height: "40vh", width: "100%" }}>
                <HorizontalBarChart
                  //data={this.state.group2}
                  data={this.state.selectedGroup}
                  handleClick={this.handleClick}
                  dimension={this.state.selectedDimension}
                  categories="key"
                  values="value"
                  barCoalor={"steelblue"}
                  labels
                  xAxis
                  yAxis
                  yAxisTop
                  // _total={this.mainDim.top(Infinity).length}
                  total={this.total}
                />
              </div>
              <hr />
              <strong>Available Dimensions</strong>
              <NestOptions
                data={this.nestDimensionOptions}
                nestUpdate={this.nestUpdate}
                colorScale={this.state.colorScale}
                colorByGroup={this.state.colorByGroup}
                colorByDimension={this.state.colorByDimension}
                handleClick={this.handleClick}
                // total={this.mainDim.top(Infinity).length}
                total={this.total}
              />
            </div>
            {/* <div className="col-sm-2">
              {/* <div style={{ height: "60vh", width: "100%" }}>
                <strong>Available Dimensions</strong>
                <NestOptions
                  data={this.nestDimensionOptions}
                  nestUpdate={this.nestUpdate}
                  colorScale={this.state.colorScale}
                  colorByGroup={this.state.colorByGroup}
                  colorByDimension={this.state.colorByDimension}
                  handleClick={this.handleClick}
                  total={this.mainDim.top(Infinity).length}
                />
              </div> */}
            {/* </div> */}
            <div className="col-sm-9">
              <div style={{ height: "100%", width: "100%" }}>
                <VerticalTree
                  data={this.dataRoot}
                  clickNode={this.clickNode}
                  data2={this.state.group2}
                  nodes={this.dataRoot.leaves().length}
                  registerZoomFunction={this.registerZoomFunction}
                />
              </div>

              {/* <strong>Data Sets</strong>
              <BubbleChartControl
                sortBy={sortBy}
                colorBy={colorBy}
                clusterBy={clusterBy}
                layoutBy={layoutBy}
                handleChange={this.handleBubbleControl}
              /> */}
              {/* <div style={{ height: "100%", width: "100%" }}> */}
              {/* <div>
                <BubbleChart
                  data={this.state.mainDim}
                  handleClick={this.handleClick}
                  dimension={this.dim5}
                  categories="key"
                  values="value"
                  barCoalor={"steelblue"}
                  color={this.state.color}
                  labels
                  xAxis
                  yAxis
                  yAxisTop
                  clickNode={this.clickNode}
                  sortFunction={this.sortFunction}
                  clusterBy={this.state.clusterBy}
                  clusterByDim={this.state.clusterByDim}
                  action={this.state.action}
                />
              </div> */}
            </div>
            {/* <div className="col-sm-2">
              <div style={{ height: "50vh", width: "100%" }}>
                <strong>Legend (Color By Option)</strong>
                <Legend
                  colorScale={this.state.colorScale}
                  colorByGroup={this.state.colorByGroup}
                  colorByDimension={this.state.colorByDimension}
                  handleClick={this.handleClick}
                  total={this.mainDim.top(Infinity).length}
                />
              </div>
              <hr />
              <div style={{ height: "50vh", width: "100%" }}>
                <h6>Node Details</h6>
                <NodeDetails
                  node={this.state.clickedNode}
                  colorScale={this.state.colorScale}
                  colorByGroup={this.state.colorByGroup}
                  total={this.mainDim.top(Infinity).length}
                />
              </div>
            </div> */}
            {/* <div className="col-sm">
              <Facet
                data={this.state.group1}
                handleClick={this.handleClick}
                dimension={this.dim1}
              />
            </div>
            <div className="col-sm">
              <Facet
                data={this.state.group2}
                handleClick={this.handleClick}
                dimension={this.dim2}
              />
            </div> */}
          </div>
          <div className="row" ref={anchor => (this.dataSetTableRef = anchor)}>
            <div className="col-sm-12">
              <DataSetTable
                // data={this.mainDim.top(
                //   this.displayOptionsRows[this.state.displayNo]
                // )}
                dimensions={this.allDimensions}
                data={this.mainDim.top(Infinity)}
                displayNo={this.state.displayNo}
                displayOptions={this.displayOptions}
                changeOption={this.changeDisplayOption}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CatalogExplorer;
