import React, { Component } from "react";

const RESULT_LIMIT_PER_CATEGORY = 5;

export default class SearchBarResult extends Component {
  constructor(props) {
    super(props);
    // console.log(props);
    this.followLink = this.followLink.bind(this);
    this.zoomToNode = this.zoomToNode.bind(this);
  }

  followLink(e) {
    this.props.hide();
  }

  filterDimension(e) {
    e.clearAllFilters();
    e.setLikeFilter(this.props.searchText);
    this.props.hide();
    this.props.updateVisualization();
  }

  filterDimensionMember(dimension, member) {
    dimension.clearAllFilters();
    dimension.setFilter(member);
    this.props.hide();
    this.props.updateVisualization();
  }

  zoomToNode(d) {
    // console.log("zoomToNode", d);
    this.props.zoomToNode(d);
    this.props.hide();
  }

  render() {
    // console.log("SEARCHBARRESULT", this.props);
    let data = this.props.result;
    let structureData = this.props.structureResult;

    return (
      <div
        className="top-bar-search-result visible"
        onMouseLeave={this.props.hide}
      >
        {data.map(d => (
          <div>
            <h3 onClick={e => this.filterDimension(d.dimension)}>
              {d.dimension.name + " (" + d.matches.length + ")"}
            </h3>
            {d.matches.slice(0, 5).map(e => (
              <p onClick={f => this.filterDimensionMember(d.dimension, e)}>
                {e.substr(0, 50)}
              </p>
            ))}
          </div>
        ))}
        <hr />
        <div>
          <h3>{"Nodes (" + structureData.length + ")"}</h3>
          {structureData.map(d => (
            <p onClick={e => this.zoomToNode(d)}>{d.data.key.substr(0, 50)}</p>
          ))}
        </div>
      </div>
    );
  }
}
