import crossfilter from "crossfilter/crossfilter.min.js";

let dimensions = [];
let groups = [];

let cf = null;

export function initCrossFilter(data) {
  var new_data = data.map(function(iter) {
    return {
      DATASET: iter.dataset,
      DATASET_DESCRIPTION: iter.dataset_description,
      DATA_ENTITY_NAME: iter.data_entity,
      DATA_SUBJECT_AREA_NAME: iter.data_subject_area,
      DIVISION: iter.division,
      INFORMATION_CLASS: iter.information_class,
      ORGANIZATION: iter.organization,
      OWNER: iter.owner,
      SYSTEM_NAME: iter.system_name,
      SYSTEM_TYPE: iter.system_type,
      id: iter.id,
      search: iter.search,
    }
  });
  cf = crossfilter.crossfilter(new_data);
  return cf;
}

function searchDim(dim, str) {
  return Object.keys(dim.members).filter(
    d => d.toUpperCase().indexOf(str.toUpperCase()) !== -1
  );
}

export function listalldims() {
  return dimensions;
}

export function searchdims(str) {
  let ret = [];

  dimensions.filter(e => e.searchScope).forEach(d => {
    let matches = searchDim(d, str);
    if (matches.length > 0) {
      ret.push({
        dimension: d,
        matches: matches
      });
    }
  });
  return ret;
}

export function createDimension(name, d, s) {
  let dima = cf.dimension(d);

  dima.name = name;
  dima.filterApplied = 0;
  if (s) dima.searchScope = true;
  else dima.searchScope = false;

  dima.createGroup = function(d) {
    var group = this.group();
    groups.push(group);
    return group;
  };

  dima.clearAllFilters = function() {
    dimensions.forEach(d => d.resetFilters());
  };

  let dimGroup = dima.group();
  let dimMembers = {};

  dimGroup.all().forEach(function(e) {
    dimMembers[e.key] = { filtered: 0 };
  });

  dimGroup.dispose();

  dima.members = dimMembers;
  //dima.filterApplied = 0;

  dima.toggleFilter = function(m) {
    //dima.members[m].filtered = 1 - dima.members[m].filtered;
    //dima.filterFunction(dima._filterFunction);

    if (!dima.filterApplied) {
      for (var propt in dima.members) {
        dima.members[propt].filtered = 1;
      }

      dima.members[m].filtered = 0;

      dima.filterApplied = 1;
    } else {
      dima.members[m].filtered = 1 - dima.members[m].filtered;
    }
    dima.filterFunction(dima._filterFunction);
  };

  dima.setLikeFilter = function(m) {
    dima.likeFilter = m.toUpperCase();

    for (var propt in dima.members) {
      dima.members[propt].filtered =
        propt.toUpperCase().indexOf(dima.likeFilter) > -1 ? 1 : 0;
    }

    //dima.members[m].filtered = 1;
    dima.filterApplied = 1;
    dima.filterFunction(dima._filterFunction);

    //dima.filterFunction(dima._filterFunctionWithLike);
  };

  dima.resetFilters = function() {
    for (var propt in dima.members) {
      dima.members[propt].filtered = 0;
    }
    dima.likeFilter = "";
    dima.filterApplied = 0;
    dima.filterAll();
  };

  dima.setFilter = function(m) {
    // console.log(m);
    // console.log(">" + m + "<");
    // console.log(dima.members[m]);
    if (dima.members[m].filtered === 1) {
      dima.members[m].filtered = 0;
      dima.filterApplied = 0;
      dima.filterAll();
    } else {
      for (var propt in dima.members) {
        dima.members[propt].filtered = 0;
      }

      dima.members[m].filtered = 1;
      dima.filterApplied = 1;
      dima.filterFunction(dima._filterFunction);
      return;
    }
    return;
  };

  dima._filterFunction = function(d) {
    // return dima.filtersApplied ? dima.members[d].filtered : 1;
    return dima.members[d].filtered;
  };

  // dima._filterFunctionWithLike = function(d) {
  //   // return dima.filtersApplied ? dima.members[d].filtered : 1;
  //   return d.toUpperCase().indexOf(dima.likeFilter) > -1 ? 1 : 0;
  // };

  dimensions.push(dima);

  return dima;
}

export function createGroup(d) {
  var group;

  group = groups.push(group);
  return group;
}
