import React, { Component } from "react";
import LogoutBtn from '../../../LogoutBtn';
import SearchBar from "../SearchBar";

export default class NavBar extends Component {
  constructor(props) {
    super(props);
    // console.log("NAVBAR", props);
    this.handleScroll = this.handleScroll.bind(this);
    this.scrollPos = props.scrollPos;
  }

  handleScroll(e) {
    this.scrollPos = 1 - this.scrollPos;
    this.props.scrollDown();
    this.forceUpdate();
  }

  render() {
    // console.log("NAVBAR render", this.props);
    return (
      <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top">
        <img
          src="/logo.png"
          height="30"
          className="d-inline-block align-top"
          alt=""
        />
        <div className="navbar-brand ml-2">Catalog Explorer</div>
        <div className="navbar-header ">
          <SearchBar
            updateVisualization={this.props.updateVisualization}
            searchFunction={this.props.searchFunction}
            searchStructure={this.props.searchStructure}
            zoomToNode={this.props.zoomToNode}
          />
        </div>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item nav-link" onClick={this.handleScroll}>
              {this.scrollPos ? "View Datasets" : "Back to Visual"}
            </li>

            <li
              className={
                this.props.filterApplied
                  ? "nav-item nav-link"
                  : "nav-item nav-link disabled"
              }
              onClick={this.props.resetFilters}
            >
              Reset Filters
            </li>
            <li className="nav-item nav-link m-l-xl ">
              Total Datasets in Scope: {this.props.total}
            </li>
            {/* <li className="nav-item nav-link" onClick={this.props.downloadData}>
              Download Data
            </li> */}
          </ul>
        </div>
        {/* <div onClick={this.handleScroll}>
          <small>{this.scrollPos ? "View Datasets" : "Back to Visual"}</small>
        </div> */}
        {this.props.offline ? (
          <div className="text-danger">Offline Static Data</div>
        ) : null}
        {<LogoutBtn />}
      </nav>
    );
  }
}
