import React, { Component } from "react";
import * as d3 from "d3";

import "./VerticalTree.css";

let d3labeler = function() {
  var lab = [],
    anc = [],
    w = 1, // box width
    h = 1, // box width
    labeler = {};

  var max_move = 5.0,
    max_angle = 0.5,
    acc = 0,
    rej = 0;

  // weights
  var w_len = 0.2, // leader line length
    w_inter = 1.0, // leader line intersection
    w_lab2 = 30.0, // label-label overlap
    w_lab_anc = 30.0, // label-anchor overlap
    w_orient = 3.0; // orientation bias

  // booleans for user defined functions
  var user_energy = false,
    user_schedule = false;

  var user_defined_energy, user_defined_schedule;

  var energy = function(index) {
    // energy function, tailored for label placement

    var m = lab.length,
      ener = 0,
      dx = lab[index].x - anc[index].x,
      dy = anc[index].y - lab[index].y,
      dist = Math.sqrt(dx * dx + dy * dy),
      overlap = true,
      amount = 0,
      theta = 0;

    // penalty for length of leader line
    if (dist > 0) ener += dist * w_len;

    // label orientation bias
    dx /= dist;
    dy /= dist;
    if (dx > 0 && dy > 0) {
      ener += 0 * w_orient;
    } else if (dx < 0 && dy > 0) {
      ener += 1 * w_orient;
    } else if (dx < 0 && dy < 0) {
      ener += 2 * w_orient;
    } else {
      ener += 3 * w_orient;
    }

    var x21 = lab[index].x,
      y21 = lab[index].y - lab[index].height + 2.0,
      x22 = lab[index].x + lab[index].width,
      y22 = lab[index].y + 2.0;
    var x11, x12, y11, y12, x_overlap, y_overlap, overlap_area;

    for (var i = 0; i < m; i++) {
      if (i != index) {
        // penalty for intersection of leader lines
        overlap = intersect(
          anc[index].x,
          lab[index].x,
          anc[i].x,
          lab[i].x,
          anc[index].y,
          lab[index].y,
          anc[i].y,
          lab[i].y
        );
        if (overlap) ener += w_inter;

        // penalty for label-label overlap
        x11 = lab[i].x;
        y11 = lab[i].y - lab[i].height + 2.0;
        x12 = lab[i].x + lab[i].width;
        y12 = lab[i].y + 2.0;
        x_overlap = Math.max(0, Math.min(x12, x22) - Math.max(x11, x21));
        y_overlap = Math.max(0, Math.min(y12, y22) - Math.max(y11, y21));
        overlap_area = x_overlap * y_overlap;
        ener += overlap_area * w_lab2;
      }

      // penalty for label-anchor overlap
      x11 = anc[i].x - anc[i].r;
      y11 = anc[i].y - anc[i].r;
      x12 = anc[i].x + anc[i].r;
      y12 = anc[i].y + anc[i].r;
      x_overlap = Math.max(0, Math.min(x12, x22) - Math.max(x11, x21));
      y_overlap = Math.max(0, Math.min(y12, y22) - Math.max(y11, y21));
      overlap_area = x_overlap * y_overlap;
      ener += overlap_area * w_lab_anc;
    }
    return ener;
  };

  var mcmove = function(currT) {
    // Monte Carlo translation move

    // select a random label
    var i = Math.floor(Math.random() * lab.length);

    // save old coordinates
    var x_old = lab[i].x;
    var y_old = lab[i].y;

    // old energy
    var old_energy;
    if (user_energy) {
      old_energy = user_defined_energy(i, lab, anc);
    } else {
      old_energy = energy(i);
    }

    // random translation
    lab[i].x += (Math.random() - 0.5) * max_move;
    lab[i].y += (Math.random() - 0.5) * max_move;

    // hard wall boundaries
    if (lab[i].x > w) lab[i].x = x_old;
    if (lab[i].x < 0) lab[i].x = x_old;
    if (lab[i].y > h) lab[i].y = y_old;
    if (lab[i].y < 0) lab[i].y = y_old;

    // new energy
    var new_energy;
    if (user_energy) {
      new_energy = user_defined_energy(i, lab, anc);
    } else {
      new_energy = energy(i);
    }

    // delta E
    var delta_energy = new_energy - old_energy;

    if (Math.random() < Math.exp(-delta_energy / currT)) {
      acc += 1;
    } else {
      // move back to old coordinates
      lab[i].x = x_old;
      lab[i].y = y_old;
      rej += 1;
    }
  };

  var mcrotate = function(currT) {
    // Monte Carlo rotation move

    // select a random label
    var i = Math.floor(Math.random() * lab.length);

    // save old coordinates
    var x_old = lab[i].x;
    var y_old = lab[i].y;

    // old energy
    var old_energy;
    if (user_energy) {
      old_energy = user_defined_energy(i, lab, anc);
    } else {
      old_energy = energy(i);
    }

    // random angle
    var angle = (Math.random() - 0.5) * max_angle;

    var s = Math.sin(angle);
    var c = Math.cos(angle);

    // translate label (relative to anchor at origin):
    lab[i].x -= anc[i].x;
    lab[i].y -= anc[i].y;

    // rotate label
    var x_new = lab[i].x * c - lab[i].y * s,
      y_new = lab[i].x * s + lab[i].y * c;

    // translate label back
    lab[i].x = x_new + anc[i].x;
    lab[i].y = y_new + anc[i].y;

    // hard wall boundaries
    if (lab[i].x > w) lab[i].x = x_old;
    if (lab[i].x < 0) lab[i].x = x_old;
    if (lab[i].y > h) lab[i].y = y_old;
    if (lab[i].y < 0) lab[i].y = y_old;

    // new energy
    var new_energy;
    if (user_energy) {
      new_energy = user_defined_energy(i, lab, anc);
    } else {
      new_energy = energy(i);
    }

    // delta E
    var delta_energy = new_energy - old_energy;

    if (Math.random() < Math.exp(-delta_energy / currT)) {
      acc += 1;
    } else {
      // move back to old coordinates
      lab[i].x = x_old;
      lab[i].y = y_old;
      rej += 1;
    }
  };

  var intersect = function(x1, x2, x3, x4, y1, y2, y3, y4) {
    var mua, mub;
    var denom, numera, numerb;

    denom = (y4 - y3) * (x2 - x1) - (x4 - x3) * (y2 - y1);
    numera = (x4 - x3) * (y1 - y3) - (y4 - y3) * (x1 - x3);
    numerb = (x2 - x1) * (y1 - y3) - (y2 - y1) * (x1 - x3);

    /* Is the intersection along the the segments */
    mua = numera / denom;
    mub = numerb / denom;
    if (!(mua < 0 || mua > 1 || mub < 0 || mub > 1)) {
      return true;
    }
    return false;
  };

  var cooling_schedule = function(currT, initialT, nsweeps) {
    // linear cooling
    return currT - initialT / nsweeps;
  };

  labeler.start = function(nsweeps) {
    // main simulated annealing function
    var m = lab.length,
      currT = 1.0,
      initialT = 1.0;

    for (var i = 0; i < nsweeps; i++) {
      for (var j = 0; j < m; j++) {
        if (Math.random() < 0.5) {
          mcmove(currT);
        } else {
          mcrotate(currT);
        }
      }
      currT = cooling_schedule(currT, initialT, nsweeps);
    }
  };

  labeler.width = function(x) {
    // users insert graph width
    if (!arguments.length) return w;
    w = x;
    return labeler;
  };

  labeler.height = function(x) {
    // users insert graph height
    if (!arguments.length) return h;
    h = x;
    return labeler;
  };

  labeler.label = function(x) {
    // users insert label positions
    if (!arguments.length) return lab;
    lab = x;
    return labeler;
  };

  labeler.anchor = function(x) {
    // users insert anchor positions
    if (!arguments.length) return anc;
    anc = x;
    return labeler;
  };

  labeler.alt_energy = function(x) {
    // user defined energy
    if (!arguments.length) return energy;
    user_defined_energy = x;
    user_energy = true;
    return labeler;
  };

  labeler.alt_schedule = function(x) {
    // user defined cooling_schedule
    if (!arguments.length) return cooling_schedule;
    user_defined_schedule = x;
    user_schedule = true;
    return labeler;
  };

  return labeler;
};

let formatValue = d3.format(".3s");

//let node = 0;
//let clusters = [];
let nodes = [];
let maxRadius = 0;
let clusterPadding = 0; //3;
let padding = 10; //1.5;
let allnodes = [];

export default class VerticalTree extends Component {
  constructor(props) {
    super(props);

    this.resize = false;
    // If onClickNode function provided via props, call it when nodes are clicked/selected

    // this.root = props.data.map(function(d) {
    //   return {
    //     name: d.SYSTEM_NAME,
    //     description: d.SYSTEM_DESCRIPTION,
    //     createDate: d.CREATE_DATE,
    //     type: d.SYSTEM_TYPE,
    //     organization: d.ORGANIZATION,
    //     cluster: 0,
    //     radius: 4
    //   };
    // });

    // this.types = [...new Set(this.root.map(d => d.type))];

    // this.typeIndex = {};

    // this.types.forEach((d, i) => (this.typeIndex[d] = i));

    // if (props.onClickNode) this.onClickNodeHandler = props.onClickNode;
    // else this.onClickNodeHandler = false;

    this.createChart = this.createChart.bind(this);
    this.onResize = this.onResize.bind(this);
    this.x = this.x.bind(this);
    this.y = this.y.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleClusterClick = this.handleClusterClick.bind(this);
    this.zoomToNode = this.zoomToNode.bind(this);
  }

  x(d) {
    return d[this.props.values];
  }

  y(d) {
    return d[this.props.categories];
  }

  onResize() {
    // Set flag to resize = true to drive a re-draw in createChart
    this.resize = true;
    this.createChart(this.props.data);
  }

  componentDidMount() {
    // Add resize event handler to re-size and re-draw when window is resized
    window.addEventListener("resize", this.onResize, false);
    this.mountChart();
    this.createChart(this.props.data);
    this.props.registerZoomFunction(this.zoomToNode);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize, false);
  }

  componentDidUpdate() {
    this.createChart(this.props.data);
  }

  handleClick(e) {
    this.props.clickNode(e);
  }

  handleClusterClick(e) {
    //console.log("handle cluster click");
    //console.log(e, e.key, this.props.clusterBy, this.props.clusterByDim);
    let dim = this.props.clusterByDim;
    this.props.handleClick(d3.event, e.name, dim);
  }

  mountChart() {}

  updateChart(chartData) {}

  createChart(chartData) {
    // console.log("vertical tree createchart");
    if (this.props.action == "CLICKED_NODE") return;

    const comp = this;

    const anchor = this.anchor;

    const parentWidth = this.anchor.clientWidth;
    const parentHeight = this.anchor.clientHeight;
    const nodeParentHeight = this.nodeParent.clientHeight;

    const headerHeight = 50;

    const margin = { top: 0, right: 0, bottom: 0, left: 0 };
    let height = nodeParentHeight - margin.top - margin.bottom; // - 5;

    const width = parentWidth - margin.left - margin.right;
    const svgref = this.svgref;

    //let vLayout = d3.pack().size([width, height]);

    //console.log(width, parentWidth, height, parentHeight);

    //vLayout.radius(d => 8);

    // let vRoot2;

    // if (this.props.clusterBy === 0) {
    //   vRoot2 = d3
    //     .hierarchy({
    //       key: "agency",
    //       children: chartData,
    //       SYSTEM_TYPE: "Database"
    //     })
    //     .sum(d => 1)
    //     .sort((a, b) => {
    //       return comp.props.sortFunction(a, b);
    //     });
    // }

    // if (this.props.clusterBy === 1) {
    //   vLayout.padding(d => (d.depth == 0 ? 40 : 0));
    //   let vData = d3
    //     .nest()
    //     .key(d => d.SYSTEM_TYPE)
    //     .entries(chartData);
    //   vRoot2 = { key: "root", values: vData };
    //   vRoot2 = d3
    //     .hierarchy(vRoot2, d => d.values)
    //     .sum(d => 1)
    //     .sort((a, b) => {
    //       return comp.props.sortFunction(a, b);
    //     });
    // }
    // if (this.props.clusterBy === 2) {
    //   vLayout.padding(d => (d.depth == 0 ? 40 : 0));
    //   let vData = d3
    //     .nest()
    //     .key(d => d.ORGANIZATION)
    //     .entries(chartData);
    //   vRoot2 = { key: "root", values: vData };
    //   vRoot2 = d3
    //     .hierarchy(vRoot2, d => d.values)
    //     .sum(d => 1)
    //     .sort((a, b) => {
    //       return comp.props.sortFunction(a, b);
    //     });
    // }

    // if (this.props.clusterBy === 3) {
    //   vLayout.padding(d => (d.depth == 0 ? 40 : 0));
    //   let vData = d3
    //     .nest()
    //     .key(d => d.DIVISION)
    //     .entries(chartData);
    //   vRoot2 = { key: "root", values: vData };
    //   vRoot2 = d3
    //     .hierarchy(vRoot2, d => d.values)
    //     .sum(d => 1)
    //     .sort((a, b) => {
    //       return comp.props.sortFunction(a, b);
    //     });
    // }

    // window.vroot = vRoot2;

    // vLayout(vRoot2);

    const svgnode = this.svgref;
    let zoom = d3
      .zoom()
      .scaleExtent([1 / 1000, 10])
      .on("zoom", zoomed);

    this.zoom = zoom;

    d3.select(svgnode)
      .style("width", parentWidth)
      .style("height", height + margin.top + margin.bottom)
      .call(zoom);

    d3.select(svgnode)
      .selectAll("rect")
      .data([0])
      .enter()
      .append("rect")
      .attr("width", parentWidth)
      .attr("height", height + margin.top + margin.bottom)
      .style("fill", "none")
      .style("pointer-events", "all");
    //.call(zoom);
    // .call(
    //   d3
    //     .zoom()
    //     .scaleExtent([1 / 4, 8])
    //     .on("zoom", zoomed)
    // );

    //d3.select(svgnode).call(zoom);

    //d3.select(svgnode).call(zoom.transform, d3.zoomIdentity.scale(1));
    //.transition()
    //.duration(750)
    // .call( zoom.transform, d3.zoomIdentity.translate(0, 0).scale(1) ); // not in d3 v4
    //.call(zoom.transform, d3.zoomIdentity);

    //    d3.select(svgnode).call(zoom);
    //.transition()
    //.duration(750)

    // let minX = vRoot2.x - vRoot2.r,
    //   maxX = vRoot2.x + vRoot2.r,
    //   minY = vRoot2.x + vRoot2.r,
    //   maxY = vRoot2.y + vRoot2.r;

    // let scale =
    //   0.98 /
    //   Math.max(1, Math.max((vRoot2.r * 2) / width, (vRoot2.r * 2) / height));

    // //console.log(scale);

    // let translate = [
    //   width / 2 - vRoot2.x * scale, //width / 2 - (scale * (maxX - minX)) / 2,
    //   height / 2 - vRoot2.y * scale //(scale * (maxY - minY)) / 2
    // ];

    // console.log(minX, maxX, minY, maxY, scale);

    // d3.select(svgnode)
    //   .transition()
    //   .duration(750);
    // .call(zoom.transform, 1.1); //d3.zoomIdentity);
    //.call(zoom.translateTo, vRoot2.x, vRoot2.y);
    //   .call(
    //     zoom.transform,
    //     //d3.zoomIdentity.scale(scale) //
    //     d3.zoomIdentity.translate(translate[0], translate[1]).scale(scale)
    //   ); // updated for d3 v4

    function zoomed() {
      let transForm = d3.event.transform;
      d3.select(svgnode)
        .selectAll("g.content")
        //.transition()
        //.duration(250)
        // .call(zoom.transform, d3.zoomIdentity);
        .attr("transform", transForm);
    }
    d3.select(svgnode)
      .selectAll("g.content")
      .remove();

    d3.select(svgnode)
      .selectAll("g.main")
      .data([0])
      .enter()
      .append("g")
      //.call(zoom)
      .attr("class", "main")
      .attr("transform", "translate(" + margin.left + "," + 30 + ")");

    this.svgGroup = d3
      .select(svgnode)
      .selectAll("g.main")
      .selectAll("g.content")
      .data([0])
      .enter()
      .append("g")
      //.call(zoom)
      .attr("class", "content")
      .attr("transform", "translate(" + 0 + "," + 0 + ")");

    var center = { x: width / 2, y: height / 2 };
    var forceStrength = 0.04;

    function charge(d) {
      return -forceStrength * Math.pow(10, 2.0);
    }

    let ticks = 0;

    function ticked2() {
      ticks++;
      //if (ticks < 10) console.log("ticked");
      //vNodesEnter.selectAll("circle").attr("r", Math.random() * 20);
      let tickzy = 0;
      // vNodesEnter.attr("transform", function(d) {
      //   if (tickzy++ < 2 && ticks < 10) console.log(d);
      //   return (
      //     "translate(" +
      //     (d.x + Math.random() * 10 - 5) +
      //     "," +
      //     (d.y + Math.random() * 10 - 5) +
      //     ")"
      //   );
      // });
      //   vNodesEnter.attr("transform", function(d, i) {
      //     return "translate(" + d.x + "," + d.y + ")";
      //   });
    }

    // function ticked() {
    //   vNodesEnter
    //     .attr("cx", function(d) {
    //       return d.x;
    //     })
    //     .attr("cy", function(d) {
    //       return d.y;
    //     });
    // }

    // var forceCollide = d3
    //   .forceCollide()
    //   .radius(function(d) {
    //     return 1;
    //   })
    //   .iterations(1);

    // var simulation = d3
    //   .forceSimulation()
    //   //.force("center", d3.forceCenter())
    //   .force("collide", forceCollide)
    //   .velocityDecay(0.2)
    //   .force(
    //     "x",
    //     d3
    //       .forceX()
    //       .strength(forceStrength)
    //       .x(center.x)
    //   )
    //   .force(
    //     "y",
    //     d3
    //       .forceY()
    //       .strength(forceStrength)
    //       .y(center.y)
    //   )
    //   .force("charge", d3.forceManyBody().strength(charge))
    //   .on("tick", ticked);

    // simulation.stop();

    //let nodesData = vRoot.descendants();
    // let nodesData = vRoot2.leaves();

    // let vNodes = d3
    //   .select(svgnode)
    //   .select("g.content")
    //   .selectAll("circle")
    //   .data(nodesData, d => d.data.id);

    // let vNodesEnter = vNodes
    //   .enter()
    //   .append("circle")
    //   .style("stroke", "grey")
    //   .style("fill", function(d) {
    //     return comp.props.color(d); //color(comp.typeIndex[d.data.SYSTEM_TYPE]);
    //   })
    //   .on("click", d => this.handleClick(d))
    //   .on("mouseover", d => this.handleClick(d))
    //   .attr("r", 1e-6)
    //   .attr("cx", function(d) {
    //     if (d.parent) return d.parent.x;

    //     return width / 2; //d.x;
    //   })
    //   .attr("cy", function(d) {
    //     if (d.parent) return d.parent.y;
    //     return height / 2; //d.y;
    //   })
    //   .transition("GROWNODES")
    //   .duration(500)
    //   .delay(function(d, i) {
    //     return i / 3;
    //   })
    //   .attr("r", 6)
    //   .attr("cx", function(d) {
    //     return d.x;
    //   })
    //   .attr("cy", function(d) {
    //     return d.y;
    //   });

    // if (this.props.clusterBy > 0) {
    //   let nodeLabels = vRoot2.children.map(d => {
    //     return {
    //       x: d.x - (d.data.key.length / 2) * 12,
    //       y: d.y - d.r,
    //       name: d.data.key,
    //       height: 40,
    //       width: (d.data.key.length + 7) * 12,
    //       childrenLength: d.data.values.length
    //     };
    //   });
    //   let nodeAnchors = vRoot2.children.map(d => {
    //     return { x: d.x, y: d.y, r: d.r };
    //   });

    //   d3labeler()
    //     .label(nodeLabels)
    //     .anchor(nodeAnchors)
    //     .width(width)
    //     .height(height)
    //     .start(2000);

    //   let vNodeLabel = d3
    //     .select(svgnode)
    //     .select("g.content")
    //     .selectAll("text")
    //     .data(nodeLabels);

    //   vNodeLabel
    //     .enter()
    //     .append("text")
    //     .style("stroke", "white")
    //     .style("stroke-width", "1px")
    //     .style("paint-order", "stroke")
    //     .attr("style", "cursor:pointer")
    //     //.attr("x", d => d.x - (d.name.length / 2) * 8)
    //     .attr("x", d => width / 2)
    //     //.attr("x", d => d.x)
    //     //.attr("x", d => d.x - (d.data.key.length / 2) * 8)
    //     //.attr("y", d => yScale(d.key));
    //     //.attr("y", d => d.y - d.r)
    //     //.attr("y", d => d.y) // - d.r)
    //     .attr("y", d => height / 2) // - d.r)
    //     //.text(d => d.data.key);
    //     .text(d => d.name + " (" + d.childrenLength + ")")
    //     .on("click", d => this.handleClusterClick(d))
    //     .transition("ENTERNODES")
    //     .duration(500)
    //     .delay(function(d, i) {
    //       return i * 50;
    //     })
    //     //.attr("x", d => d.x - (d.name.length / 2) * 8)
    //     .attr("x", d => d.x)
    //     //.attr("y", d => yScale(d.key));
    //     //.attr("y", d => d.y - d.r)
    //     .attr("y", d => d.y);

    //   vNodeLabel
    //     //.merge()
    //     .transition()
    //     .duration(500)
    //     .delay(function(d, i) {
    //       return i / 3;
    //     })
    //     //.attr("x", d => d.x - (d.name.length / 2) * 8)
    //     .attr("x", d => d.x)
    //     //.attr("y", d => yScale(d.key));
    //     //.attr("y", d => d.y - d.r)
    //     .attr("y", d => d.y)
    //     //.text(d => d.data.key);
    //     .text(d => d.name + " (" + d.childrenLength + ")");
    //   //.on("click", d => this.handleClusterClick(d));

    //   vNodeLabel.exit().remove();
    // } else {
    //   d3.select(svgnode)
    //     .select("g.content")
    //     .selectAll("text")
    //     .remove();
    // }

    // vNodes
    //   .exit()
    //   .transition("SHRINKNODES")
    //   .delay(function(d, i) {
    //     return i / 8;
    //   })
    //   .duration(100)
    //   .attr("r", 1e-6)
    //   .remove();

    // vNodes
    //   .transition("MOVENODES")
    //   .duration(500)
    //   .delay(function(d, i) {
    //     return i / 3;
    //   })
    //   .attr("r", 6)
    //   .style("fill", function(d) {
    //     return comp.props.color(d); //color(comp.typeIndex[d.data.SYSTEM_TYPE]);
    //   })
    //   .attr("cx", function(d) {
    //     return d.x;
    //   })
    //   .attr("cy", function(d) {
    //     return d.y;
    //   });

    var treemap = d3.tree().size([180 * this.props.nodes, height * 0.9]);

    // maps the node data to the tree layout

    //treemap.children(d => d.values);

    let nodes = treemap(this.props.data);

    window.nodes = nodes;

    function wrap(text, width) {
      text.each(function() {
        var text = d3.select(this);
        var words;

        if (text.text().indexOf("_") > -1) {
          words = text
            .text()
            .split(/_/)
            .reverse();
        } else if (text.text().indexOf(" ") > -1) {
          words = text
            .text()
            .split(/\s+/)
            .reverse();
        } else {
          words = text
            .text()
            .match(/.{1,11}/g)
            .reverse();
        }

        var word;
        var line = [];
        var lineNumber = 0;
        var lineHeight = 1.1; // ems
        var y = text.attr("y");
        var dy = parseFloat(text.attr("dy"));

        var tspan = text
          .text(null)
          .append("tspan")
          .attr("x", 0)
          .attr("y", y)
          .attr("dy", dy + "em");

        while ((word = words.pop())) {
          line.push(word);
          tspan.text(line.join(" "));
          if (tspan.node().getComputedTextLength() > width) {
            line.pop();
            tspan.text(line.join(" "));
            line = [word];
            tspan = text
              .append("tspan")
              .attr("x", 0)
              .attr("y", y)
              .attr("dy", ++lineNumber * lineHeight + dy + "em")
              .text(word);
          }
        }
        tspan = text
          .append("tspan")
          .attr("x", 0)
          .attr("y", y)
          .attr("dy", ++lineNumber * lineHeight + dy + "em")
          .text(
            d =>
              d.children || d._children
                ? "(" + d.datasets + " - " + d.dataset_percent + "%)"
                : null
          );
      });
    }

    function zoomFit(sel, paddingPercent, transitionDuration) {
      var bounds = sel.node().getBBox();

      // console.log(bounds);

      var parent = sel.node().parentElement.parentElement;
      // console.log(parent);
      var fullWidth = parent.clientWidth,
        fullHeight = parent.clientHeight;
      // console.log(fullWidth, fullHeight);
      var width = bounds.width,
        height = bounds.height;
      var midX = bounds.x + width / 2,
        midY = bounds.y + height / 2;
      if (width == 0 || height == 0) return; // nothing to fit
      var scale =
        (paddingPercent || 0.75) /
        Math.max(width / fullWidth, height / fullHeight);
      var translate = [
        fullWidth / 2 - scale * midX,
        fullHeight / 2 - scale * midY
      ];

      // console.log("zoomFit", translate, scale);
      // sel
      //   .transition()
      //   .duration(transitionDuration || 0) // milliseconds
      //   .call(zoom.translate(translate).scale(scale).event);

      sel.call(
        zoom.transform,
        //d3.zoomIdentity.scale(scale) //
        d3.zoomIdentity.translate(translate[0], translate[1]).scale(scale)
      ); // updated for d3 v4

      d3.select(parent).call(
        zoom.transform,
        //d3.zoomIdentity.scale(scale) //
        d3.zoomIdentity.translate(translate[0], translate[1]).scale(scale)
      );
    }

    // d3.select(svgnode)
    //   .selectAll("g.content")
    //   .select(".node")
    //   .remove();

    var link = d3
      .select(svgnode)
      .selectAll("g.content")
      .selectAll(".link")
      .data(
        nodes.descendants().slice(1)
        //.filter(d => d.height != 0)
      )
      .enter()
      .append("path")
      .attr("class", "link")
      .attr("d", function(d) {
        return (
          "M" +
          d.x +
          "," +
          d.y +
          "C" +
          d.x +
          "," +
          (d.y + d.parent.y) / 2 +
          " " +
          d.parent.x +
          "," +
          (d.y + d.parent.y) / 2 +
          " " +
          d.parent.x +
          "," +
          d.parent.y
        );
      });

    // adds each node as a group
    var node = d3
      .select(svgnode)
      .selectAll("g.content")
      .selectAll(".node")
      .data(
        nodes.descendants()
        //.filter(d => d.height != 0)
      )
      .enter()
      .append("g")
      .on("click", d => {
        this.props.clickNode(d);
      })
      .attr("class", function(d) {
        return "node" + (d._children ? " node--internal" : " node--leaf");
      })
      .attr("transform", function(d) {
        return "translate(" + d.x + "," + d.y + ")";
      });

    // adds the circle to the node
    node
      .append("circle")
      .attr("class", function(d) {
        return "node" + (d._children ? " node--internal" : " node--leaf");
      })
      .attr("r", 10);

    // adds the text to the node
    node
      .append("text")
      .attr("dy", ".35em")
      .attr("y", function(d) {
        return d.children ? -20 : 20;
      })
      .style("text-anchor", "middle")
      .text(function(d) {
        if (d.children || d._children) return d.data.key;
        else return d.data.DATASET;
      });

    node.selectAll("text").call(wrap, 100);

    zoomFit(this.svgGroup, 0.9, 0);

    return;
  }

  zoomToNode = function(d) {
    //let zoom = d3.zoom().scaleExtent([1 / 1000, 10]);
    //.on("zoom", zoomed);

    // console.log("zoom to node", d.x, d.y);
    var bounds = this.svgGroup.node().getBBox();
    var parent = this.svgGroup.node().parentElement.parentElement;
    // console.log(parent);
    var fullWidth = parent.clientWidth,
      fullHeight = parent.clientHeight;

    // console.log(fullWidth, fullHeight);

    window.d = d;

    if (this.currentNode)
      d3.select(
        "g[transform='translate(" +
          this.currentNode.x +
          "," +
          this.currentNode.y +
          ")']"
      ).attr("font-weight", "normal");

    this.currentNode = d;

    let nos = d3.select("g[transform='translate(" + d.x + "," + d.y + ")']");
    nos.attr("font-weight", "bold");

    // console.log(nos);
    window.nos = nos;

    this.svgGroup
      .transition()
      .duration(1500)
      .call(
        this.zoom.transform,
        //d3.zoomIdentity.scale(scale) //
        d3.zoomIdentity.translate(-d.x + fullWidth / 2, -20).scale(1)
      );

    d3.select(this.svgGroup.node().parentElement.parentElement).call(
      this.zoom.transform,
      //d3.zoomIdentity.scale(scale) //
      d3.zoomIdentity.translate(-d.x + fullWidth / 2, -20).scale(1)
    );
  };

  // forceTick1(e) {
  //   allnodes
  //     .each(this.cluster(6 * e.alpha * e.alpha))
  //     .each(this.collide(0.2))
  //     .attr("cx", function(d) {
  //       return d.x;
  //     })
  //     .attr("cy", function(d) {
  //       return d.y;
  //     });
  // }

  // cluster1(alpha) {
  //   return function(d) {
  //     var cluster = this.clusters[d.cluster];
  //     if (cluster === d) return;
  //     var x = d.x - cluster.x,
  //       y = d.y - cluster.y,
  //       l = Math.sqrt(x * x + y * y),
  //       r = d.radius + cluster.radius;
  //     if (l != r) {
  //       l = ((l - r) / l) * alpha;
  //       d.x -= x *= l;
  //       d.y -= y *= l;
  //       cluster.x += x;
  //       cluster.y += y;
  //     }
  //   };
  // }

  // collide1(alpha) {
  //   var quadtree = d3.quadtree(nodes);
  //   return function(d) {
  //     var r = 2 + maxRadius + Math.max(padding, clusterPadding),
  //       nx1 = d.x - r,
  //       nx2 = d.x + r,
  //       ny1 = d.y - r,
  //       ny2 = d.y + r;
  //     quadtree.visit(function(quad, x1, y1, x2, y2) {
  //       if (quad.point && quad.point !== d) {
  //         var x = d.x - quad.point.x,
  //           y = d.y - quad.point.y,
  //           l = Math.sqrt(x * x + y * y),
  //           r =
  //             d.radius +
  //             quad.point.radius +
  //             (d.cluster === quad.point.cluster ? padding : clusterPadding);
  //         if (l < r) {
  //           l = ((l - r) / l) * alpha;
  //           d.x -= x *= l;
  //           d.y -= y *= l;
  //           quad.point.x += x;
  //           quad.point.y += y;
  //         }
  //       }
  //       return x1 > nx2 || x2 < nx1 || y1 > ny2 || y2 < ny1;
  //     });
  //   };
  // }

  render() {
    // console.log("vertical tree render");
    return (
      <div className="VerticalTree" ref={anchor => (this.anchor = anchor)}>
        {/* <div style={{ height: "50px" }}>
          <svg
            ref={svgref => (this.svgrefHeader = svgref)}
            width={this.width}
            height={"50px"}
          />
        </div> */}
        <div
          style={{
            overflowY: "hidden",
            overflowX: "hidden",
            height: "85vh"
          }}
          ref={anchor => (this.nodeParent = anchor)}
        >
          <svg
            ref={svgref => (this.svgref = svgref)}
            width={this.width}
            height={this.height}
          />
        </div>
      </div>
    );
  }
}
