import React, { Component } from "react";

import SearchBarResult from "./SearchBarResult.js";
export default class SearchBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchText: "",
      displayResult: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.hideResults = this.hideResults.bind(this);
    this.handleKey = this.handleKey.bind(this);
  }

  handleChange(e) {
    //console.log(e.target.value);
    let result = [];
    let structureResult = [];
    if (e.target.value.length >= 2) {
      result = this.props.searchFunction(e.target.value);
      // console.log(result);
      structureResult = this.props.searchStructure(e.target.value);
      // console.log(structureResult);
      this.setState({
        searchText: e.target.value,
        structureResult: structureResult,
        result: result,
        displayResult: true
      });
    } else {
      this.setState({
        searchText: e.target.value,
        result: [],
        structureResult: [],
        displayResult: false
      });
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    //console.log('Submit',e);
    this.setState({
      // result: this.props.search(this.state.searchText),
      displayResult: true
    });
  }

  hideResults() {
    this.setState({
      displayResult: false,
      searchText: ""
    });
  }

  handleKey(e) {
    //console.log("you pressed",e.keyCode);

    // Escape Key Pressed
    if (e.keyCode == 27) {
      this.setState({
        displayResult: false,
        searchText: ""
      });
    }
  }

  render() {
    // console.log("SEARCHBAR", this.props);
    return (
      <form
        autoComplete="off"
        className="navbar-form-custom"
        onSubmit={this.handleSubmit}
      >
        {/*
      <div className="input-group">
          <div className="form-group has-feedback has-clear">
            <input type="text" className="form-control" id="exampleInput1" placeholder="Enter text here" />
            <span className="form-control-clear glyphicon glyphicon-remove form-control-feedback"></span>
          </div>
        </div>
*/}
        <div className="input-group">
          <input
            type="text"
            placeholder="Search..."
            className="form-control"
            value={this.state.searchText}
            onChange={this.handleChange}
            onKeyDown={this.handleKey}
            name="top-search"
            id="top-search"
          />
          {/*<span class="form-control-clear glyphicon glyphicon-remove form-control-feedback"></span>*/}

          {this.state.displayResult ? (
            <SearchBarResult
              hide={this.hideResults}
              searchText={this.state.searchText}
              result={this.state.result}
              updateVisualization={this.props.updateVisualization}
              structureResult={this.state.structureResult}
              zoomToNode={this.props.zoomToNode}
            />
          ) : null}
          {/* <div className={this.state.displayResult ? "top-bar-search-result visible": "top-bar-search-result"}>
                  <h1>test</h1>
                  op
              </div>*/}
        </div>
      </form>
    );
  }
}
